

import {Injectable} from '@angular/core';

import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';

import {catchError, map } from 'rxjs/operators';
import {DataStorageService} from './data-storage.service';
import {environment} from '../../environments/environment';


@Injectable({providedIn: 'root'})

export class MainInterceptorService implements HttpInterceptor {

    constructor(public storage: DataStorageService) {

    }

    private started;

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.started = Date.now();

        let clonedReq: any;

        if (req.url.startsWith(environment.globalHostname)) {

            clonedReq = req.clone({

                headers: req.headers

                    .set('LANGUAGE',  this.storage.getItem('language') || environment.defaultLang || 'en')
                    .set('X-nob-app', 'Api-request')
                    .set('Authorization', 'Bearer ' + this.storage.token || null)
            });
        } else {
            clonedReq = req;
        }

        return next.handle(clonedReq).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {

                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }));

    }

}


