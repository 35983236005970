import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {LangEnum} from '../_feature/lang.enum';

@Injectable()
export class DataStorageService {

    private data: any = {
        access_token : null,
        language : environment.defaultLang || 'en'
    };

    constructor() {

        const language = localStorage.getItem('language') || null;

        if (!language || !LangEnum[language]) {
            this.setItem('language', environment.defaultLang || 'en');
        }

        for (let i = 0; i < localStorage.length; ++i) {
            const key = localStorage.key(i);
            this.data[key] = localStorage.getItem(key);
        }
    }

    get token(): string {
        return this.data.access_token;
    }

    get envLang(): string {
        return this.data.language;
    }

    public setLang(lang: LangEnum): void {
        this.setItem('language', lang);
    }

    public setItem(key: string, value: any): void {
        this.data[key] = value;

        if (value instanceof Array || value instanceof Object) {
            value = JSON.stringify(value);
        }

        localStorage.setItem(key, value);
    }

    public getItem(key: string, parse?: ParseStorageEnum): any {

        const parsec: string = parse || 'none';

        if (!this.data[key]) {
            return null;
        }

        switch (parsec) {

            case 'json':

                if (this.data[key] instanceof Array || this.data[key] instanceof Object) {

                    return this.data[key];
                } else {
                    return JSON.parse(this.data[key]);
                }

            case 'int':
                return parseInt(this.data[key]);

            case 'float':
                return parseFloat(this.data[key]);

            case 'boolean':
                return JSON.parse(this.data[key]);
        }

        return this.data[key];

    }

    public clear(): void {

        this.data = { access_token : null , language : 'es'};

        localStorage.clear();
    }

    public removeItem(key: string): void {

        localStorage.removeItem(key);

        this.data[key] = null;
        delete this.data[key];
    }

}

export enum ParseStorageEnum {
    json = 'json',
    int = 'int',
    float = 'float',
    boolean = 'boolean'
}
