import {ApisRoutesCommons} from './apis-routes.interface';

export class CommonRoutes {
    // common files
    public commons: ApisRoutesCommons = {
        token_files: {
            upload: '/commons/token-files/upload',
            del: '/commons/token-files/delete'
        },
        token: '/commons/token',
        files: {
            entity: '/commons/files',
            rules: '/commons/files/rules',
            upload: '/commons/files/upload',
            del: '/commons/files/delete'
        }
    };
}
