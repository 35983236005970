import { Injectable } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {DataStorageService} from './data-storage.service';
import {AlertService} from './alert.service';

@Injectable({
    providedIn: 'root'
})
export class ApiResponseErrorService {

    constructor(public storage: DataStorageService,
                public _n: AlertService,
                public location: Router) { }


    public catchHttpError(err: HttpErrorResponse, e: boolean = true) {

        this.storage.setItem('apiProcessing', 'false');

        this.storage.setItem('isFinding', false);

        switch (err.status) {

            case 400:
            case 401:
            case 422: {
                this._n.notify(err.error.message, 'danger');
                break;
            }

            case 423: {
                const layout = document.getElementsByTagName('app-private-layout')[0];

                if (layout) {
                    const ev = new CustomEvent('openPasswordConfirm');
                    layout.dispatchEvent(ev);
                    return;
                }
                break;
            }

            case 511: {
                this._n.notify(err.error.message, 'danger');
                this.dropSession();
                break;
            }

            case 307: {

                const obj = {
                    redirect_url: err.error.body.redirect_url
                };

                const social = document.getElementsByTagName('app-social')[0];

                if (social) {
                    const ev = new CustomEvent('reRequest', {detail: obj});
                    social.dispatchEvent(ev);
                    return;
                }

                break;
            }

            default: {
                if (e) {
                    this._n.notify(err.error.message, 'danger');
                }
                console.log(err.statusText);
                break;
            }
        }
    }

    private dropSession() {

        const lang = this.storage.envLang;

        this.storage.clear();

        this.storage.setItem('language', lang);

        this.location.navigateByUrl('/login');
    }
}
