import { Injectable } from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';


@Injectable({
    providedIn: 'root'
})
export class ModalService {
    constructor (private ngbModal: NgbModal) {

    }

    private _config: ModalOptionInterface = {
        centered: true,
        keyboard: false,
        backdrop: 'static',
        duration : 200
    };

    open (content: any, config?: ModalOptionInterface) {

        config = { ...this._config, ...config };

        const modal = this.ngbModal.open(content, config);
        const instance = (modal as any)._windowCmptRef.instance;

        const fx = (modal as any)._removeModalElements.bind(modal);
        (modal as any)._removeModalElements = () => {
            instance.windowClass = config.windowClass ?  config.windowClass + ' out-modal' : 'out-modal';
            setTimeout(fx,  config.duration || 200);
        };

        return modal;
    }
}

export interface ModalOptionInterface extends NgbModalOptions {

    duration?: number;
    scrollable?: boolean;

}

