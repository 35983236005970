<div class="modal-header">
    <i class="fas fa-key text-info"></i>
    <h4 class="modal-title">{{lang?.title}}</h4>
    <button type="button" class="close" aria-label="Close" (keydown.enter)="doEnter($event)" (click)="modal.dismiss('cancel')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <ngx-spinner [fullScreen]="false" [name]="spinnerName"
                 bdColor="rgba(255,255,255,0.7)"
                 color="#343a40"
                 zIndex="1051"
                 size="medium">
    </ngx-spinner>

    <form [formGroup]="group" autocomplete="off">
        <div class="form-group mb-3">

            <input-field [label]="lang.password"
                         model="password"
                         [group]="group"
                         [form_dynamic]="false"
                         [submitted]="submitted"
                         inputType="password"
                         required='true'>
            </input-field>
        </div>
    </form>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">{{lang.buttons.cancel}}</button>
    <button type="button" class="btn btn-info"
            (click)="doSend()"
            [disabled]="!group.dirty && !group.touched">
        {{lang?.buttons?.confirm}}
    </button>
</div>
