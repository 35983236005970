import {Component, AfterViewInit, Input, ElementRef, OnDestroy, forwardRef, NgZone, SimpleChanges} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { uuid, isTextarea, bindHandlers, mergePlugins } from '../utils/Utils';
import { getTinymce } from '../utils/TinyMCE';
import { EventsClass } from '../utils/Events';

const EDITOR_COMPONENT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Tinymce),
  multi: true
};

@Component({
  selector: 'tinymce',
  template: '<ng-template></ng-template>',
  styles: [':host { display: block; }'],
  providers: [EDITOR_COMPONENT_VALUE_ACCESSOR]
})
export class Tinymce extends EventsClass implements AfterViewInit, ControlValueAccessor, OnDestroy {
  private elementRef: ElementRef;
  private element: Element | undefined = undefined;
  private editor: any;

  ngZone: NgZone;

  @Input() id = '';
  @Input() content_style = '';
  @Input() initialValue: string | undefined;
  @Input() tagName: string | undefined;
  @Input() formControlName: string;
  @Input() required: boolean;

  private onTouchedCallback = () => {};
  private onChangeCallback = (x: any) => {};

  private __default__ = {
    height: 300,
    remove_script_host: true,
    relative_urls: false,
    skin: false,
    content_css: '',
    plugins: [],
    toolbar: false,
    block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4',
    menu: {},
    menubar: false,
    external_plugins: {},
    branding: false,
    statusbar: false,
    document_base_url: './',
    readonly: false,
    inline: false,
    setup: null,
    paste_data_images: true,
    extended_valid_elements: 'span[class],i[class]'
  };

  constructor(elementRef: ElementRef, ngZone: NgZone) {
    super();
    this.elementRef = elementRef;
    this.ngZone = ngZone;
    this.initialize = this.initialize.bind(this);
  }

  set(key: string, value: any) {
    this.__default__[key] = value;
  }

  push(key: string, value: any) {
    this.__default__[key].push(value);
  }

  setToolbar(value: any) {
    this.__default__.toolbar = this.__default__.toolbar ? this.__default__.toolbar + ' | ' + value : value;
  }

  writeValue(value: string | null): void {
    this.initialValue = value || this.initialValue;

    if (this.editor && this.editor.initialized && typeof value === 'string') {
      this.editor.setContent(value);
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (this.editor) {
      this.editor.setMode(isDisabled ? 'readonly' : 'design');
    } else if (isDisabled) {
      this.__default__ = { ...this.__default__, readonly: true };
    }
  }

  ngAfterViewInit() {

    if (this.content_style) {
      this.__default__['content_style'] = this.content_style;
    }

    this.id = this.id || uuid('tinymce');
    this.createElement();
    if (getTinymce() !== null) {
      this.initialize();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const changeName in changes) {
      const change = changes[changeName];
      const previousValue = JSON.stringify(change.previousValue);
      const currentValue  = JSON.stringify(change.currentValue);

      if (changeName === 'content_style') {
        if ((previousValue === 'null' && previousValue !== undefined) && currentValue !== null) {
          this.set('content_style', currentValue);
        }
      }
    }
  }

  ngOnDestroy() {
    if (getTinymce() !== null) {
      getTinymce().remove(this.editor);
    }
  }

  createElement() {
    const tagName = typeof this.tagName === 'string' ? this.tagName : 'div';
    this.element = document.createElement(this.__default__.inline ? tagName : 'textarea');
    if (this.element) {
      this.element.id = this.id;
      if (isTextarea(this.element)) {
        this.element.style.visibility = 'hidden';
      }
      this.element.setAttribute('formControlName', this.formControlName);
      if ( this.required ) {
        this.element.setAttribute('required', 'true');
      }
      this.elementRef.nativeElement.appendChild(this.element);
    }
  }

  initialize() {
    const finalInit = {
      ...this.__default__,
      selector: `#${this.id}`,
      inline: this.__default__.inline,
      // plugins: mergePlugins(this.__default__.plugins),
      // toolbar: this.toolbar || (this.init && this.init.toolbar),
      setup: (editor: any) => {
        this.editor = editor;
        editor.on('init', (e: Event) => {
          this.initEditor(e, editor);
        });

        if (this.__default__ && typeof this.__default__.setup === 'function') {
          this.__default__.setup(editor);
        }
      }
    };

    if (isTextarea(this.element)) {
      this.element.style.visibility = '';
    }

    this.ngZone.runOutsideAngular(() => {
      getTinymce().init(finalInit);
    });
  }

  private initEditor(initEvent: Event, editor: any) {
    if (typeof this.initialValue === 'string') {
      this.ngZone.run(() => editor.setContent(this.initialValue));
    }
    editor.once('blur', () => this.ngZone.run(() => this.onTouchedCallback()));
    editor.on(
        'setcontent',
        ({ content, format }: any) => format === 'html' && content && this.ngZone.run(() => this.onChangeCallback(content))
    );
    editor.on('change keyup undo redo', () => this.ngZone.run(() => this.onChangeCallback(editor.getContent())));
    bindHandlers(this, editor, initEvent);
  }
}
