<div class="lists-files-preview" *ngFor="let item of items let j = index">

    <div class="list-image" *ngIf="imagesType.includes(item.type)">
        <img [src]="item.base ? item.base : item.src" [alt]="item.id" />
        <ngx-dropzone-label class="dropzone1 text-center">{{ item.type }}</ngx-dropzone-label>
        <ngx-dropzone-remove-badge (click)="doDelete(item)" *ngIf="deleteItem"></ngx-dropzone-remove-badge>
        <file-edit-badge (click)="doEdit(item)"></file-edit-badge>
    </div>

    <div class="list-video" *ngIf="videosType.includes(item?.type)">
        <video width="320" height="240" controls (click)="$event.stopPropagation()">
            <source [src]="item.base ? item.base : item.src" />
        </video>
        <ngx-dropzone-remove-badge (click)="doDelete(item)" *ngIf="deleteItem"></ngx-dropzone-remove-badge>
        <file-edit-badge (click)="doEdit(item)"></file-edit-badge>
        <file-copy-badge (click)="doCopy(item.src)" *ngIf="item.src && !viewItem"></file-copy-badge>

    </div>

    <div class="list-file" *ngIf="!imagesType.includes(item.type) && !videosType.includes(item?.type)">
        <ngx-dropzone-label class="dropzone1"><i class="{{iconType(item.type)}}"></i></ngx-dropzone-label>
        <ngx-dropzone-remove-badge (click)="doDelete(item)" *ngIf="deleteItem"></ngx-dropzone-remove-badge>
        <file-edit-badge (click)="doEdit(item)"></file-edit-badge>
        <file-copy-badge (click)="doCopy(item.src)" *ngIf="item.src && !viewItem"></file-copy-badge>
        <file-view-badge (click)="handleView(item)" *ngIf="item.src && viewItem"></file-view-badge>
    </div>

</div>
