import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { Tinymce } from './tinymce';

@Directive({
  selector: 'mce-plugin',
  inputs: ['plugins']
})
export class McePlugin implements AfterViewInit {

  @Input() plugins: string | undefined;

  constructor(private _elem: ElementRef, private _mce: Tinymce) {}

  ngAfterViewInit(): void {

    if ( this.plugins && this.plugins.length > 0 ) {
      this._mce.push('plugins', this.plugins);
    }

  }

}
