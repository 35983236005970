import {
    ApisRoutesAdminInterface,
    ApisRoutesContactInterface,
    ApisRoutesCrudHourHolidayInterface,
    ApiRoutesTripInterface,
    ApisRoutesCrudInterface,
    ApisRoutesImportInterface,
    ApisRoutesInterface,
    ApisRoutesPlacesInterface,
    ApisRoutesReasonInterface,
    ApisRoutesReactiveInterface,
    ApisRoutesSadminInterface,
    ApisRoutesSimpleReadInterface,
    ApisRoutesSystemInterface,
    ApisRoutesVerificationInterface,
    ApiRoutesPaymentMethodsInterface,
    ApisRoutesTravelHistoryInterface,
    ApisRoutesDriverInterface, ApisRoutesMonitoringInterface,
} from './apis-routes.interface';

export class AdminRoutes {
    // admin
    public admin: ApisRoutesCrudInterface = {
        list: '/admin/admin',
        create: '/admin/admin/create',
        read: '/admin/admin/read',
        update: '/admin/admin/update',
        del: '/admin/admin/delete',
    };

    // credential
    public credential: ApisRoutesInterface = {
        path: '/admin/credentials/rank'
    };
    // Import file
    public import: ApisRoutesImportInterface = {
        file_brand: '/admin/import/brand-model'
    };
    // Simple Read
    public simple_read: ApisRoutesSimpleReadInterface = {
        country: '/admin/country/simple-read',
        city: '/admin/city/simple-read',
        state: '/admin/state/simple-read'
    };

    // rank
    public rank: ApisRoutesCrudInterface = {
        list: '/admin/rank',
        create: '/admin/rank/create',
        read: '/admin/rank/read',
        update: '/admin/rank/update',
        del: '/admin/rank/delete',
        select: '/admin/rank/role'
    };
    // Requirement-group
    public requirement_group: ApisRoutesCrudInterface = {
        list: '/admin/group-requirement',
        create: '/admin/group-requirement/create',
        read: '/admin/group-requirement/read',
        update: '/admin/group-requirement/update',
        del: '/admin/group-requirement/delete',
        select: '/admin/group-requirement/select',
        order: '/admin/group-requirement/order'
    };
    // Brand
    public brand: ApisRoutesCrudInterface = {
        list: '/admin/brand',
        create: '/admin/brand/create',
        read: '/admin/brand/read',
        update: '/admin/brand/update',
        del: '/admin/brand/delete'
    };
    // Model
    public model: ApisRoutesCrudInterface = {
        list: '/admin/model',
        create: '/admin/model/create',
        read: '/admin/model/read',
        update: '/admin/model/update',
        del: '/admin/model/delete'
    };
    // Version
    public version: ApisRoutesCrudInterface = {
        list: '/admin/version',
        create: '/admin/version/create',
        read: '/admin/version/read',
        update: '/admin/version/update',
        del: '/admin/version/delete',
    };

    // Monitoring
    public monitoring: ApisRoutesMonitoringInterface = {
        list: '/admin/supervision',
        canceled: '/admin/trip/canceled',
    };

    // role
    public role: ApisRoutesCrudInterface = {
        list: '/admin/role',
        create: '/admin/role/create',
        read: '/admin/role/read',
        update: '/admin/role/update',
        del: '/admin/role/delete',
        select: '/admin/role/admin'
    };
    // type_place
    public type_place: ApisRoutesCrudInterface = {
        list: '/admin/place-type',
        create: '/admin/place-type/create',
        read: '/admin/place-type/read',
        update: '/admin/place-type/update',
        del: '/admin/place-type/delete',
        select: '/admin/place-type/select'
    };

    // places
    public places: ApisRoutesPlacesInterface = {
        list: '/admin/place',
        create: '/admin/place/create',
        read: '/admin/place/read',
        update: '/admin/place/update',
        del: '/admin/place/delete',
        select: '/admin/place/select',
        point: '/admin/place/aproximate'
    };

    // trip-description
    public trip_description: ApisRoutesCrudInterface = {
        list: '/admin/trip-description',
        create: '/admin/trip-description/create',
        read: '/admin/trip-description/read',
        update: '/admin/trip-description/update',
        del: '/admin/trip-description/delete',
        select: '/admin/trip-description/select'
    };
    // Car list
    public car_list: ApisRoutesCrudInterface = {
        list: '/admin/verification/car',
        read: '/admin/driver/read',
        del: '/admin/verification/car/delete',
        select: '/admin/verification/car/select'
    };
    // Capacity
    public capacity: ApisRoutesCrudInterface = {
        list: '/admin/capacity',
        read: '/admin/capacity/read',
        del: '/admin/capacity/delete',
        update: '/admin/capacity/update',
        create: '/admin/capacity/create',
        select: '/admin/capacity/select'
    };

    // contact driver
    public contact_driver: ApisRoutesContactInterface = {
        list: '/admin/driver/contact',
        read: '/admin/driver/contact/read',
        open: '/admin/driver/contact/open',
        close: '/admin/driver/contact/close',
        send: '/admin/driver/contact/message/send',
        message: '/admin/driver/contact/message',
        select: '/admin/driver/contact/reason',
    };
    // contact users
    public contact_user: ApisRoutesContactInterface = {
        list: '/admin/user/contact',
        read: '/admin/user/contact/read',
        open: '/admin/user/contact/open',
        close: '/admin/user/contact/close',
        send: '/admin/user/contact/message/send',
        message: '/admin/user/contact/message',
        select: '/admin/user/contact/reason',
    };

    // verification
    public verification: ApisRoutesVerificationInterface = {
        list: '/admin/verification',
        read: '/admin/verification/read',
        select: '/admin/verification/reasons',
        verify: '/admin/verification/verify',
        status: '/admin/verification/status',
        del: '/admin/verification/delete',
        accept: '/admin/verification/approve',
        cancel: '/admin/verification/cancel',
        requirement_cancel: '/admin/verification/requirement-cancel',

    };
    // withdrawal-method
    public withdrawalMethod: ApisRoutesCrudInterface = {
        list: '/admin/withdrawal-method',
        create: '/admin/withdrawal-method/create',
        read: '/admin/withdrawal-method/read',
        update: '/admin/withdrawal-method/update',
        del: '/admin/withdrawal-method/delete',
        select: '/admin/withdrawal-method/select'
    };
    // income-method
    public incomeMethod: ApisRoutesCrudInterface = {
        list: '/admin/income-method',
        create: '/admin/income-method/create',
        read: '/admin/income-method/read',
        update: '/admin/income-method/update',
        del: '/admin/income-method/delete',
        select: '/admin/income-method/select'
    };

    // income-field-option
    public incomeFieldOption: ApisRoutesCrudInterface = {
        list: '/admin/income-method/field/option',
        create: '/admin/income-method/field/option/create',
        read: '/admin/income-method/field/option/read',
        update: '/admin/income-method/field/option/update',
        del: '/admin/income-method/field/option/delete',
        select: '/admin/income-method/field/option/select'
    };

    // withdrawal-field-option
    public withdrawalFieldOption: ApisRoutesCrudInterface = {
        list: '/admin/withdrawal-method/field/option',
        create: '/admin/withdrawal-method/field/option/create',
        read: '/admin/withdrawal-method/field/option/read',
        update: '/admin/withdrawal-method/field/option/update',
        del: '/admin/withdrawal-method/field/option/delete',
        select: '/admin/withdrawal-method/field/option/select'
    };

    // field
    public field: ApisRoutesCrudInterface = {
        list: '/admin/withdrawal-method/field',
        create: '/admin/withdrawal-method/field/create',
        read: '/admin/withdrawal-method/field/read',
        update: '/admin/withdrawal-method/field/update',
        del: '/admin/withdrawal-method/field/delete',
        select: '/admin/withdrawal-method/field/select'
    };

    // income-field
    public incomeField: ApisRoutesCrudInterface = {
        list: '/admin/income-method/field',
        create: '/admin/income-method/field/create',
        read: '/admin/income-method/field/read',
        update: '/admin/income-method/field/update',
        del: '/admin/income-method/field/delete',
        select: '/admin/income-method/field/select'
    };

    // country
    public country: ApisRoutesCrudHourHolidayInterface = {
        list: '/admin/country',
        create: '/admin/country/create',
        read: '/admin/country/read',
        update: '/admin/country/update',
        select: '/admin/country/select',
        rush_hour_delete: '/admin/country/rush-hour/delete',
        rush_hour_create: '/admin/country/rush-hour/create',
        rush_hour_list: '/admin/country/rush-hour',
        rush_hour_update: '/admin/country/rush-hour/update',
        rush_hour_read: '/admin/country/holiday/read',
        holiday_delete: '/admin/country/holiday/delete',
        holiday_create: '/admin/country/holiday/create',
        holiday_list: '/admin/country/holiday',
        holiday_update: '/admin/country/holiday/update',
        holiday_read: '/admin/country/holiday/read',
        select_read_country: '/admin/country/select-read',
    };

    // preference
    public preference: ApisRoutesCrudInterface = {
        list: '/admin/preference',
        create: '/admin/preference/create',
        read: '/admin/preference/read',
        update: '/admin/preference/update',
        select: '/admin/preference/select',
        del: '/admin/preference/delete',
    };

    // state
    public state: ApisRoutesCrudInterface = {
        list: '/admin/state',
        create: '/admin/state/create',
        read: '/admin/state/read',
        update: '/admin/state/update',
        del: '/admin/state/delete',
        select: '/admin/state/select'
    };
    // city
    public city: ApisRoutesCrudHourHolidayInterface = {
        list: '/admin/city',
        create: '/admin/city/create',
        read: '/admin/city/read',
        update: '/admin/city/update',
        del: '/admin/city/delete',
        select: '/admin/city/select',
        rush_hour_delete: '/admin/city/rush-hour/delete',
        rush_hour_create: '/admin/city/rush-hour/create',
        rush_hour_list: '/admin/city/rush-hour',
        rush_hour_update: '/admin/city/rush-hour/update',
        rush_hour_read: '/admin/city/holiday/read',
        holiday_delete: '/admin/city/holiday/delete',
        holiday_create: '/admin/city/holiday/create',
        holiday_list: '/admin/city/holiday',
        holiday_update: '/admin/city/holiday/update',
        holiday_read: '/admin/city/holiday/read',
    };

    // reason
    public reason: ApisRoutesReasonInterface = {
        list: '/admin/reason',
        create: '/admin/reason/create',
        read: '/admin/reason/read',
        update: '/admin/reason/update',
        del: '/admin/reason/delete',
        select: '/admin/reason/select'
    };

    // document_type
    public document_type: ApisRoutesCrudInterface = {
        list: '/admin/document-type',
        create: '/admin/document-type/create',
        read: '/admin/document-type/read',
        update: '/admin/document-type/update',
        del: '/admin/document-type/delete',
        select: '/admin/document-type/select'
    };

    // aside
    public aside: ApisRoutesSadminInterface = {
        list: '/admin/aside',
        create: '/admin/aside/create',
        read: '/admin/aside/read',
        update: '/admin/aside/update',
        del: '/admin/aside/delete'
    };

    // user
    public user: ApisRoutesCrudInterface = {
        list: '/admin/user',
        create: '/admin/user/create',
        read: '/admin/user/read',
        update: '/admin/user/update',
        del: '/admin/user/delete',
        select: '/admin/user/select'
    };

    // driver
    public driver: ApisRoutesDriverInterface = {
        list: '/admin/driver',
        create: '/admin/driver/create',
        read: '/admin/driver/read',
        update: '/admin/driver/update',
        del: '/admin/driver/delete',
        select: '/admin/driver/select',
        map: '/admin/driver/aproximate',
        recharge: '/admin/driver/balance/recharge'
    };

    // asset
    public asset: ApisRoutesCrudInterface = {
        list: '/admin/asset',
        create: '/admin/asset/create',
        read: '/admin/asset/read',
        update: '/admin/asset/update',
        del: '/admin/asset/delete'
    };

    // requirement
    public requirement: ApisRoutesCrudInterface = {
        list: '/admin/requirement',
        create: '/admin/requirement/create',
        read: '/admin/requirement/read',
        update: '/admin/requirement/update',
        del: '/admin/requirement/delete'
    };

    // options_requirement
    public option_requirement: ApisRoutesCrudInterface = {
        list: '/admin/requirement/option',
        create: '/admin/requirement/option/create',
        read: '/admin/requirement/option/read',
        update: '/admin/requirement/option/update',
        del: '/admin/requirement/option/delete',
        select: '/admin/requirement/option/select'
    };

    // module
    public module: ApisRoutesCrudInterface = {
        list: '/admin/module',
        create: '/admin/module/create',
        read: '/admin/module/read',
        update: '/admin/module/update',
        del: '/admin/module/delete',
        select: '/admin/module/select'
    };

    // system
    public system: ApisRoutesSystemInterface = {
        list: '/admin/system',
        read: '/admin/system/read',
        update: '/admin/system/update',
        public_system: {
            list: '/admin/public-system',
            read: '/admin/public-system/read',
            update: '/admin/public-system/update'
        },
        environments: '/admin/system/environments',
        autocomplete: '/admin/system/autocomplete',
        admin: {
            environments: '/admin/system/admin/environments'
        }
    };

    // inactive-user
    public inactiveUser: ApisRoutesReactiveInterface = {
        list: '/admin/user/trashed',
        reactive: '/admin/user/reactive',
        del: '/admin/user/force-delete',
    };

    // common
    public common: ApisRoutesAdminInterface = {
        profile: '/admin/profile',
        change_profile: '/admin/profile/change-profile',
        change_password: '/admin/profile/change-password',
        credentials: '/admin/credentials/auth-credentials'
    };

    // payment-method
    public payment_method: ApiRoutesPaymentMethodsInterface = {
        list: '/admin/payment-method',
        read: '/admin/payment-method/read',
        create: '/admin/payment-method/create',
        update: '/admin/payment-method/update',
        del: '/admin/payment-method/delete',
    };

    public trip_user: ApiRoutesTripInterface = {
        list: '/admin/trip/user'
    };

    public trip_driver: ApiRoutesTripInterface = {
        list: '/admin/trip/driver',
    };

    // home
    public home: ApisRoutesSadminInterface = {
        save: '/admin/home/save',
        read: '/admin/home/read',
        admin: {
            save: '/admin/home/admin/save',
            read: '/admin/home/admin/read'
        }
    };

    public travel_history: ApisRoutesTravelHistoryInterface = {
        list: '/admin/trip',
        read: '/admin/trip/read',
        monitoring_create: '/admin/supervision/create'
    };
}
