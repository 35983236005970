// Default menu settings configurations

import {MenuSettingsLang} from './menu-settings.lang';

export interface MenuItem {
    title: string;
    icon: string;
    page: string;
    credentials?: Array<string>;
    isExternalLink?: boolean;
    issupportExternalLink?: boolean;
    badge: { type: string, value: string };
    submenu: {
        items: Partial<MenuItem>[];
    };
    section: string;
}

export interface MenuConfig {
    horizontal_menu?: {
        items: Partial<MenuItem>[]
    };
    vertical_menu: {
        items: Partial<MenuItem>[]
    };
}

export class MenuSettingsConfig {

    public lang = new MenuSettingsLang(localStorage.getItem('language'));

    public menu: MenuConfig = {
        vertical_menu: {
            items: [
                {
                    title: this.lang.home,
                    icon: 'la la-file',
                    page: '/dashboard',
                    credentials: [
                        'admin_system_environments'
                    ]
                },
                {
                    title: this.lang.country,
                    icon: 'la la-map-o',
                    page: '/country',
                    credentials: [
                        'admin_system_environments',
                        'admin_country_list'
                    ]
                },
                {
                    title: this.lang.state,
                    icon: 'la la-map-marker',
                    page: '/state',
                    credentials: [
                        'admin_system_environments',
                        'admin_state_list'
                    ]
                },
                {
                    title: this.lang.map_driver,
                    icon: 'la la-map-pin',
                    page: '/map-driver',
                    credentials: [
                        'admin_system_environments',
                        'admin_driver_aproximate'
                    ]
                },
                // {
                //     title: this.lang.importFile,
                //     icon: 'la la-cloud-download',
                //     page: '/import',
                //     credentials: [
                //         'admin_system_environments',
                //         'admin_import_brand-model'
                //     ]
                // },
                {
                    title: this.lang.notifications,
                    icon: 'la la-bell-o',
                    page: 'disabled',
                    credentials: [
                        'admin_system_environments'
                    ]
                },
                {
                    title: this.lang.verification,
                    icon: 'la la-file-text',
                    page: '/verification',
                    credentials: [
                        'admin_system_environments',
                        'admin_verification_list'
                    ]
                },
                {
                    title: this.lang.users,
                    icon: 'la la-user',
                    page: 'null',
                    submenu: {
                        items: [
                            {
                                title: this.lang.users,
                                page: '/user',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_user_list'
                                ]
                            },
                            {
                                title: this.lang.drivers,
                                page: '/driver',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_driver_list'
                                ]
                            }
                        ]
                    }
                },
                {
                    title: this.lang.travels,
                    icon: 'fas fa-route',
                    page: 'null',
                    submenu: {
                        items: [
                            {
                                title: this.lang.travels_history,
                                page: '/travel-history',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_trip_list'
                                ]
                            },
                        ]
                    }
                },
                {
                    title: this.lang.trip_monitoring,
                    icon: 'fas fa-solid fa-clipboard-list',
                    page: 'null',
                    submenu: {
                        items: [
                            {
                                title: this.lang.trip,
                                page: '/monitoring',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_trip_list'
                                ]
                            },
                            {
                                title: this.lang.cancellation_monitoring,
                                page: '/canceled-trip',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_trip_list'
                                ]
                            },
                        ]
                    }
                },
                {
                    title: this.lang.payment,
                    icon: 'la la-money',
                    page: 'null',
                    submenu: {
                        items: [
                            {
                                title: this.lang.generalPayments,
                                page: 'disabled',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments'
                                ]
                            },
                            {
                                title: this.lang.withdrawal,
                                page: '/withdrawal',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_withdrawal-method_list'
                                ]
                            },
                            {
                                title: this.lang.paymentMethod,
                                page: '/payment-methods',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_payment-method_list',
                                    'admin_system_environments'
                                ]
                            },
                            {
                                title: this.lang.incomeList,
                                page: 'disabled',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments'
                                ]
                            },
                            {
                                title: this.lang.incomeMethods,
                                page: '/income',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_income-method_list'
                                ]
                            },
                        ]
                    }
                },
                {
                    title: this.lang.service,
                    icon: 'la la-wrench',
                    page: 'null',
                    submenu: {
                        items: [
                            {
                                title: this.lang.places,
                                page: '/places',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_place_list'
                                ]
                            },
                            {
                                title: this.lang.type_place,
                                page: '/type-place',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_place-type_list'
                                ]
                            },
                            {
                                title: this.lang.preference,
                                page: '/preference',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_preference_list',
                                ]
                            },
                            {
                                title: this.lang.module,
                                page: '/module',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_module_list'
                                ]
                            },
                            {
                                title: this.lang.capacity,
                                page: '/capacity',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_capacity_list'
                                ]
                            },
                            {
                                title: this.lang.rating_descriptions,
                                page: '/trip-description',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_trip-description_list'
                                ]
                            },
                        ]
                    }
                },
                {
                    title: this.lang.administration,
                    icon: 'la la-cogs',
                    page: 'null',
                    submenu: {
                        items: [
                            {
                                title: this.lang.reason,
                                icon: 'la la-thumb-tack',
                                page: '/reason',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_reason_list'
                                ]
                            },
                            {
                                title: this.lang.documentType,
                                page: '/document-type',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_document-type_list'
                                ]
                            },
                            {
                                title: this.lang.group_verification,
                                page: '/requirement-group',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_group-requirement_list'
                                ]
                            },
                            {
                                title: this.lang.request,
                                page: '/requirement',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_requirement_list'
                                ]
                            },
                            {
                                title: this.lang.vehicleList,
                                page: '/car-list',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_verification_car'
                                ]
                            },
                            {
                                title: this.lang.brand,
                                page: '/brand',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_brand_list',
                                ]
                            },
                            {
                                title: this.lang.contactUser,
                                page: '/contact-user',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_user_contact_list'
                                ]
                            },
                            {
                                title: this.lang.contactDriver,
                                page: '/contact-driver',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments',
                                    'admin_driver_contact_list'
                                ]
                            },
                            {
                                title: this.lang.complaints,
                                page: 'disabled',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments'
                                ]
                            },
                            {
                                title: this.lang.inactiveUser,
                                page: '/inactive-user',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_environments'
                                ]
                            },
                        ]
                    }
                },
                {
                    title: this.lang.system,
                    icon: 'la la-gear',
                    page: 'null',
                    submenu: {
                        items: [
                            {
                                title: this.lang.operators,
                                page: '/operators',
                                icon: '',
                                credentials: [
                                    'admin_admin_list',
                                    'admin_system_environments'
                                ]
                            },
                            {
                                title: this.lang.range,
                                page: '/ranks',
                                icon: 'fas fa-briefcase',
                                credentials: [
                                    'admin_rank_list',
                                    'admin_system_environments'
                                ]
                            },
                            {
                                title: this.lang.rols,
                                page: '/roles',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_role_list',
                                    'admin_system_environments'
                                ]
                            },
                            {
                                title: this.lang.vars,
                                page: '/system',
                                icon: 'fas fa-id-card-alt',
                                credentials: [
                                    'admin_system_lists',
                                    'admin_system_environments'
                                ]
                            },
                        ]
                    }
                }
            ]
        }
    };
}

export const menuSettingsConfig = new MenuSettingsConfig()['menu'];
