export class MenuSettingsLang implements MenuSettingsLangInterface {

    title = 'admin-sidenav';
    system = 'System';
    vars = 'Default Values';
    operators = 'Operators';
    rols = 'Roles';
    web = 'Website';
    banner = 'Banner';
    range = 'Range';
    dashboard = 'Dashboard';
    administration = 'Administration';
    users = 'Users';
    layout = 'Layout';
    about = 'About';
    contactUser = 'User contact';
    contactDriver = 'Driver contact';
    home = 'Home';
    blog = 'Blog';
    notifications = 'Notifications';
    subscribers = 'Subscribers';
    administrative = 'Administrative';
    country = 'Country';
    documentType = 'Document type';
    reason = 'Reason';
    verification = 'Identity verification';
    request = 'Requirement';
    generalPayments = 'General payment list';
    payment = 'Payments';
    withdrawal = 'Withdrawal methods';
    rechargeMethod = 'Income methods';
    paymentMethod = 'Payment methods';
    complaints = 'Complaints';
    vehicleList = 'Car list';
    incomeList = 'Income list';
    incomeMethods = 'Income methods';
    drivers = 'Drivers';
    client = 'Clients';
    inactiveUser = 'Inactive Users';
    module = 'Module';
    preference = 'Travel preference';
    brand = 'Car brands';
    service = 'Services';
    type_place = 'Types places';
    places = 'Places';
    rating_descriptions = 'Trip descriptions';
    capacity = 'Capabilities';
    state = 'States';
    importFile = 'Import';
    group_verification = 'Requirements groups';
    travels = 'Travels';
    travels_history: 'Travel history';
    map_driver = 'Driver maps';
    trip_monitoring = 'Monitoring';
    cancellation_monitoring = 'canceled trips';
    trip = 'Trips';

    public constructor(lang) {
        return this[lang] || this;
    }

    es: MenuSettingsLangInterface = {
        title: 'admin-sidenav',
        system: 'Sistema',
        vars: 'Valores predeterminados',
        operators: 'Operadores',
        rols: 'Roles',
        web: 'Pagina Web',
        banner : 'Banners',
        range: 'Rangos',
        dashboard: 'Inicio',
        administration: 'Administración',
        users: 'Usuarios',
        layout: 'Diseño',
        about: 'Nosotros',
        contactUser: 'Contacto de usuarios',
        contactDriver: 'Contacto de conductor',
        home: 'Inicio',
        blog: 'Blog',
        notifications: 'Notificaciones',
        subscribers: 'Suscriptores',
        administrative: 'Administrativo',
        country: 'País',
        documentType: 'Tipo de documento',
        reason: 'Razón',
        verification: 'Verificación de identidad',
        request: 'Requerimiento',
        generalPayments: 'Lista de pagos generales',
        payment: 'Pagos',
        withdrawal: 'Métodos de retiro',
        paymentMethod: 'Métodos de pagos',
        rechargeMethod: 'Métodos de ingreso',
        complaints: 'Denuncias',
        vehicleList: 'Lista de automóviles',
        incomeList: 'Lista de ingresos',
        incomeMethods: 'Métodos de ingresos',
        drivers: 'Conductores',
        client: 'Clientes',
        inactiveUser: 'Usuarios inactivos',
        module: 'Módulo',
        preference: 'Preferencia de viaje',
        brand: 'Marcas de autos',
        service: 'Servicios',
        type_place: 'Tipos de lugares',
        places: 'Lugares',
        rating_descriptions: 'Descripciones de los viajes',
        capacity: 'Capacidades',
        state: 'Estados',
        importFile: 'Importar',
        group_verification: 'Grupos de requerimientos',
        travels: 'Viajes',
        travels_history: 'Historial de viajes',
        map_driver: 'Mapas de conductores',
        trip_monitoring: 'Supervisión',
        cancellation_monitoring: 'Viajes cancelados',
        trip: 'Viajes',
    };

    pt: MenuSettingsLangInterface = {
        title: 'admin-sidenav',
        system: 'Sistema',
        vars: 'Valores predeterminados',
        operators: 'Operadores',
        rols: 'Roles',
        web: 'Pagina Web',
        banner : 'Banners',
        range: 'Rangos',
        dashboard: 'Inicio',
        administration: 'Administración',
        users: 'Usuarios',
        layout: 'Diseño',
        about: 'Nosotros',
        contactUser: 'Contato do usuário',
        contactDriver: 'Contato do motorista',
        home: 'Inicio',
        blog: 'Blog',
        notifications: 'Notificaciones',
        subscribers: 'Suscriptores',
        administrative: 'Administrativo',
        country: 'País',
        documentType: 'Tipo de documento',
        reason: 'Razão',
        verification: 'Verificação de Identidade',
        request: 'Requisitos',
        generalPayments: 'Lista geral de pagamentos',
        payment: 'Pagamentos',
        withdrawal: 'Métodos de retirada',
        paymentMethod: 'Métodos de Pagamento',
        rechargeMethod: 'Métodos de renda',
        complaints: 'Reclamações',
        vehicleList: 'Lista de carros',
        incomeList: 'Lista de renda',
        incomeMethods: 'Métodos de renda',
        drivers: 'Motoristas',
        client: 'clientes',
        inactiveUser: 'Usuários Inativos',
        module: 'Módulo',
        preference: 'Preferência de viagem',
        brand: 'Marcas de carros',
        service: 'Serviços',
        type_place: 'Tipos de lugares',
        places: 'Locais',
        rating_descriptions: 'Descrições de viagens',
        capacity: 'Capacidades',
        state: 'Estados',
        importFile: 'Importar',
        group_verification: 'Grupos de requisitos',
        travels: 'viagens',
        travels_history: 'Histórico de viagens',
        map_driver: 'Mapas de motorista',
        trip_monitoring: 'Supervisão',
        cancellation_monitoring: 'Viagens canceladas',
        trip: 'Viagens',
    };
}

export interface MenuSettingsLangInterface {

    title: string;
    blog: string;
    system: string;
    vars: string;
    operators: string;
    rols: string;
    web: string;
    banner: string;
    range: string;
    dashboard: string;
    administration: string;
    users: string;
    layout: string;
    about: string;
    contactUser: string;
    contactDriver: string;
    home: string;
    notifications: string;
    subscribers: string;
    administrative: string;
    country: string;
    documentType: string;
    reason: string;
    verification: string;
    request: string;
    generalPayments: string;
    payment: string;
    withdrawal: string;
    paymentMethod: string;
    rechargeMethod: string;
    complaints: string;
    vehicleList: string;
    incomeList: string;
    incomeMethods: string;
    drivers: string;
    client: string;
    inactiveUser: string;
    module: string;
    preference: string;
    brand: string;
    service: string;
    type_place: string;
    places: string;
    rating_descriptions: string;
    capacity: string;
    state: string;
    importFile: string;
    group_verification: string;
    travels: string;
    travels_history: string;
    map_driver: string;
    trip_monitoring: string;
    cancellation_monitoring: string;
    trip: string;
}
