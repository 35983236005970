export class FilesUploadLang implements FilesUploadLangInterface {

    title = 'Operadores';
    create = 'Create';
    update = 'Update';
    copy = 'Copy url';
    buttons = {
        create: 'Create record',
        update: 'Save Changes',
        del: 'Delete',
        cancel: 'Cancel',
        new: 'New'
    };
    dataAlert = {
        title: 'Warning',
        cancel: 'Cancel',
        ok: 'Accept',
        content: 'Are you sure you want to delete the File?'
    };
    name = 'Name';
    new = 'Select file';
    upload = 'Upload';
    edit = 'Update';
    loading = 'Loading';
    cancel = 'Cancel';
    upFile = 'File uploaded successfully';
    fileEmpty = 'You must upload a file';
    copy_link = 'Link copied!';

    public constructor(lang) {
        return this[lang] || this;
    }

    es: FilesUploadLangInterface = {
        title: 'Operadores',
        create: 'Crear',
        update: 'Actualizar',
        copy: 'Copiar enlace',
        buttons: {
            create: 'Crear registro',
            update: 'Guardar cambios',
            del: 'Eliminar',
            cancel: 'Cancelar',
            new: 'Nuevo'
        },
        dataAlert: {
            title: 'Precaución',
            cancel: 'Cancelar',
            ok: 'Aceptar',
            content : '¿Estas Seguro que desea eliminar el Archivo?'
        },
        name: 'Nombre',
        new: 'Seleccionar archivo',
        upload: 'Subir',
        edit: 'Actualizar',
        loading: 'Cargando',
        cancel: 'Cancelar',
        upFile: 'Archivo subido con éxito',
        fileEmpty: 'Debe subir un archivo',
        copy_link : 'Enlace copiado!',
    };

    pt: FilesUploadLangInterface = {
        title: 'Operadores',
        create: 'Crio',
        update: 'Atualizar',
        copy: 'Link de cópia',
        buttons: {
            create: 'Criar registro',
            update: 'Salvar alterações',
            del: 'Excluir',
            cancel: 'Cancelar',
            new: 'Novo'
        },
        dataAlert: {
            title: 'Aviso',
            cancel: 'Cancelar',
            ok: 'Aceitar',
            content: 'Tem certeza de que deseja excluir o arquivo?'
        },
        name: 'Name',
        new: 'Selecione o arquivo',
        upload: 'Envio',
        edit: 'Atualizar',
        loading: 'Carregando',
        cancel: 'Cancelar',
        upFile: 'Arquivo carregado com sucesso',
        fileEmpty: 'Você deve carregar um arquivo',
        copy_link: 'Link copiado!',
    };
}

export interface FilesUploadLangInterface {

    title: string;
    create: string;
    update: string;
    copy: string;
    buttons: {
        create: string,
        update: string,
        del: string,
        cancel: string,
        new: string
    };
    dataAlert: {
        title: string,
        cancel: string,
        ok: string,
        content: string
    };
    name: string;
    new: string;
    upload: string;
    edit: string;
    loading: string;
    cancel: string;
    upFile: string;
    fileEmpty: string;
    copy_link: string;
}

