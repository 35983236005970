<!--&lt;!&ndash; Page content &ndash;&gt;-->
<!--<div class="container-fluid mt&#45;&#45;7">-->
<!--    &lt;!&ndash; Table &ndash;&gt;-->
<!--    <div class="row w-100">-->
<!--        <div class="col">-->
<!--            <div class="card shadow">-->
<!--                <ngx-spinner [fullScreen]="false"-->
<!--                             [name]="spinnerName"-->
<!--                             bdColor="rgba(255,255,255,0.7)"-->
<!--                             color="#FF4961"-->
<!--                             size="medium">-->
<!--                </ngx-spinner>-->

<!--                <header-card [title]="'title'" [action]="'create'" (takeAction)="doCreate()"></header-card>-->

<!--                <div class="card-header border-0">-->
<!--                    <h3 class="mb-0">Card tables</h3>-->
<!--                </div>-->
<!--                <div class="table-responsive">-->
<!--                    <table class="table align-items-center table-flush">-->
<!--                        <thead class="thead-light">-->
<!--                        <tr>-->
<!--                            <ng-th [value]="'Nombre'"-->
<!--                                   sorteable="true"-->
<!--                                   [sortKey]="'name'"-->
<!--                                   [currentSort]="metaPaginate?.sort"-->
<!--                                   (sort)="doSort($event)"></ng-th>-->
<!--                            <ng-th [value]="'Ambito'"-->
<!--                                   sorteable="true"-->
<!--                                   [sortKey]="'module'"-->
<!--                                   [currentSort]="metaPaginate?.sort"-->
<!--                                   (sort)="doSort($event)"></ng-th>-->
<!--                            <th scope="col"></th>-->
<!--                        </tr>-->
<!--                        </thead>-->
<!--                        <tbody>-->
<!--                        <tr *ngFor="let item of items let j = index">-->
<!--                            <ng-td [value]="item['name']"></ng-td>-->
<!--                            <ng-td [value]="item['module']"></ng-td>-->
<!--                            <ng-actions [actions]="actionList" (doAction)="methodAction($event, item['id'])"></ng-actions>-->
<!--                        </tr>-->

<!--                        </tbody>-->
<!--                    </table>-->
<!--                </div>-->
<!--                <ng-paginate [paginate]="metaPaginate" (loadApi)="load($event)"></ng-paginate>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <!-- breadcrumb -->
<!--            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>-->
        </div>
        <div class="content-body">

            <ngx-spinner [fullScreen]="false"
                         [name]="spinnerName"
                         bdColor="rgba(255,255,255,0.7)"
                         color="#FF4961"
                         size="medium">
            </ngx-spinner>

            <section id="drag-area">
                <div class="row">
                    <div class="col-md-12">
                        <!-- Page content -->

                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Recent Sales</h4>
                                <div class="heading-elements">
                                    <ul class="list-inline mb-0">
                                        <li><a [routerLink]="" class="btn btn-sm btn-danger box-shadow-2 round btn-min-width pull-right">View all
                                        </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-content mt-1">
                                <div class="table-responsive" fxFlex="auto" [perfectScrollbar]="config">
                                    <table class="table border_bottom table-hover">
                                        <thead>
                                        <tr>
                                            <ng-th [value]="'test'"></ng-th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <ng-td [value]="'test'"></ng-td>
<!--                                            <td class="text-truncate p-1">-->
<!--                                                <ul class="list-unstyled users-list m-0">-->
<!--                                                    <li class="avatar avatar-sm pull-up" *ngFor="let imageUrl of row.image">-->
<!--                                                        <img class="media-object rounded-circle" [src]="imageUrl" alt="Avatar">-->
<!--                                                    </li>-->
<!--                                                </ul>-->
<!--                                            </td>-->
<!--                                            <td class="text-truncate">-->
<!--                            <span>-->
<!--                              <button type="button"-->
<!--                                      class="btn btn-sm btn-outline-{{row.type}} round">{{row.buttonname}}</button>-->
<!--                            </span>-->
<!--                                            </td>-->
<!--                                            <td class="text-truncate">-->
<!--                                                <ngb-progressbar height="7px" type="{{row.type}}" [value]=row.value>-->
<!--                                                </ngb-progressbar>-->
<!--                                            </td>-->
<!--                                            <td class="text-truncate">{{ row.amount }} </td>-->
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

