import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgTdComponent} from './ng-td.component';
import {NgProgressComponent} from './ng-progress/ng-progress.component';
import {NgStatusComponent} from './ng-status/ng-status.component';
import {NgDateComponent} from './ng-date/ng-date.component';
import {NgCurrencyComponent} from './ng-currency/ng-currency.component';
import {NgColorComponent} from './ng-color/ng-color.component';
import {NgBoolComponent} from './ng-bool/ng-bool.component';
import {NgAvatarComponent} from './ng-avatar/ng-avatar.component';
import {NgActionsComponent} from './ng-actions/ng-actions.component';
import {ComponentsModule} from '../../components.module';
import {NgNumberComponent} from './ng-number/ng-number.component';
import {NgImageComponent} from './ng-image/ng-image.component';
import {NgRatingComponent} from "./ng-rating/ng-rating.component";


@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        RouterModule.forChild([]),
        ComponentsModule,
    ],
    declarations: [
        NgTdComponent,
        NgStatusComponent,
        NgProgressComponent,
        NgDateComponent,
        NgCurrencyComponent,
        NgColorComponent,
        NgBoolComponent,
        NgAvatarComponent,
        NgActionsComponent,
        NgNumberComponent,
        NgImageComponent,
        NgRatingComponent,
    ],
    exports: [
        NgTdComponent,
        NgStatusComponent,
        NgProgressComponent,
        NgDateComponent,
        NgCurrencyComponent,
        NgColorComponent,
        NgBoolComponent,
        NgAvatarComponent,
        NgActionsComponent,
        NgNumberComponent,
        NgImageComponent,
        NgRatingComponent,
    ]
})
export class NgTdModule { }
