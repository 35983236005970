<div class="card-footer border-top-0 border-bottom-0" *ngIf="paginate">

    <ngb-pagination class="d-flex justify-content-end" *ngIf="paginate.last_page > 1"
                    [(page)]="paginate.current_page"
                    [maxSize]="5"
                    [pageSize]="paginate.per_page"
                    [collectionSize]="paginate.total"
                    [rotate]="true"
                    [ellipses]="false"
                    (pageChange)="nextPage($event)" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>
            <i class="fas fa-angle-left"></i>
        </ng-template>
        <ng-template ngbPaginationNext>
            <i class="fas fa-angle-right"></i>
        </ng-template>
    </ngb-pagination>

</div>
