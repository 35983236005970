<li class="nav-item nav-search">
    <a [routerLink]="" class="nav-link nav-link-search" (click)="searchingDoClick()">
        <i class="ficon feather ft-search" *ngIf="!searchDone"></i>
        <i class="fas fa-backspace" *ngIf="searchDone"></i>
    </a>
    <form [formGroup]="group" autocomplete="off">
        <div class="search-input" [ngClass]="{'open': isHeaderSearchOpen}">
        <input class="input"
               type="text"
               placeholder="{{lang.search}}"
               formControlName = "search" (keydown.enter)="searchingDoEnter()">
    </div>
    </form>
</li>
