import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

import {DataStorageService} from './data-storage.service';
import {environment} from '../../environments/environment';
import {ApiResponse} from '../_feature/api-response';

@Injectable()
export class AuthToolService {

    public apiUrl = environment.globalHostname;

    public lang: AuthInterface = new AuthLang(this.storage.envLang);

    constructor(
        public router: Router,
        private storage: DataStorageService
    ) {}

    public logout() {
        const lang = this.storage.envLang;

        this.storage.clear();

        this.storage.setItem('language', lang);

        this.router.navigateByUrl('/login');
    }

    public in(data: ApiResponse) {

        this.storage.setItem('access_token', data.body.access_token);

        this.storage.setItem('profile', data);

        this.router.navigateByUrl('/dashboard');

        const name: string = data.body.user.profile.name;

        const lastName: string = data.body.user.profile.last_name;

        const fullName: string = name + ' ' + lastName;

        this.storage.setItem('profile', data.body.user);

        this.storage.setItem('fullName', fullName);
    }

    get isLog(): boolean {
        const token = this.storage.token || null;
        return token !== null && (new RegExp(/^[A-Za-z0-9\.|\-\_]{35,100}$/)).test(token);
    }

    get token(): string {
        return this.storage.token;
    }

}

export class AuthLang implements AuthInterface {

    sessionClosed = 'Session user closed';

    public constructor(private lang: string) {
        return this[lang] || this;
    }

    es: AuthInterface = {
        sessionClosed: 'Sesión de usuario cerrada'
    };

    pt: AuthInterface = {
        sessionClosed: 'Sessão do usuário fechada'
    };
}

export interface AuthInterface {
    sessionClosed: string;
}

