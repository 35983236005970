import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class PasswordConfirmService {

    private confirmSource = new Subject();
    public confirmEvent = this.confirmSource.asObservable();

    constructor() {}

    public passwordConfirmed() {
        this.confirmSource.next();
    }
}
