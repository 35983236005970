<div class="form-group" [formGroup]="group">
    <label for="{{model}}">{{ label }}</label>
    <input #input="" type="{{type}}"
           id="{{model}}"
           placeholder="{{placeHolder || label}}"
           class="form-control"
           (focus)="actionTrigger()"
           (blur)="actionTrigger()"
           [disabled]="disabled"
           formControlName="{{model}}"
           [ngClass]="{ 'is-invalid': form_dynamic ? (group.controls[this.model]?.errors && !errorEmpty) : (submitted && group.controls[this.model]?.errors)}">

    <div *ngIf="form_dynamic ? (group.controls[this.model]?.errors && !errorEmpty) : (submitted && group.controls[this.model]?.errors)" class="invalid-feedback">
        {{ errors ? errors[0] : '' }}
    </div>
</div>

