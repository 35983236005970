export class NgSearchLang implements NgSearchLangInterface {

    search = 'Search';

    public constructor(lang) {
        return this[lang] || this;
    }

    es = {
        search: 'Buscar'
    };

    pt = {
        search: 'Pesquisar'
    };
}

export interface NgSearchLangInterface {

    search: string;
}
