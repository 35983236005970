import { Injectable } from '@angular/core';
import {DataStorageService, ParseStorageEnum} from './data-storage.service';
import {LangEnum} from '../_feature/lang.enum';
import {Country} from '../_layout/header/vertical/vertical.component';

@Injectable({
    providedIn: 'root'
})
export class ToolsService {

    constructor(public storage: DataStorageService) { }

    public str_to_slug (str, c?) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        const _from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
        const _to   = 'aaaaeeeeiiiioooouuuunc------';
        for (let i = 0, l = _from.length ; i < l ; i++) {
            str = str.replace(new RegExp(_from.charAt(i), 'g'), _to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, c ? c : '-') // collapse whitespace and replace by -
            .replace(/-+/g, c ? c : '-'); // collapse dashes

        return str;
    }

    public isDefined(StrJson): boolean {

        return StrJson !== 'undefined' &&
            StrJson !== undefined &&
            StrJson !== '' &&
            StrJson !== 'null' &&
            StrJson !== null;
    }

    public empty(data: any): boolean {

        switch (typeof(data)) {
            case 'undefined':
                return true;
            case 'string':
                return data === 'undefined' || data.trim().length === 0;
            case 'object':
                return data === null || Object.keys(data).length === 0;
            default:
                return false;
        }
    }

    public phoneNormalice(code, phone) {
        // +1 (123) 333-3333 - +11233333333
        phone = phone.replace(/(\s|\(|\-|\))/gi, '');

        let p = '+' + code;

        p = phone.split(p);

        phone = p[1];

        return phone;
    }

    public phoneHumize(code, phone, format = '(ddd) ddd-dddd') {
        /* 1233333333 - +1 (123) 333-3333 */

        const pc = '+' + code + ' ' + format;

        let j = 0;
        let nPhone = '';
        for (let i = 0; i < pc.length; i++) {
            if (pc[i] === 'd') {
                nPhone += phone[j];
                j++;
            } else {
                nPhone += pc[i];
            }
        }
        return nPhone;
    }

    get isLogged(): boolean {
        return !!this.storage.token;
    }

    // forms

    get strLangs() {
        return Object.keys((LangEnum)).map((lang) => {
            return '\\_' + lang;
        }).join('|');
    }

    get langEnum() {
        const lang = [];
        for ( const l in LangEnum ) {
            lang.push(LangEnum[l]);
        }
        return lang;
    }

    clearValue(data): void {
        for (const field in data) {
            if (this.empty(data[field])) {
                delete data[field];
            }
        }
    }

    structureModel(body) {
        const data = {};

        for (const field in body ) {
            if ( field !== 'translations' ) {
                data[field] = body[field];
            } else {

                for (const item of body['translations']) {
                    for (const key in item) {
                        if (key !== 'locale') {
                            data[key + '_' + item['locale']] = item[key];
                        }
                    }
                }
            }
        }
        return data;
    }

    structureParameters(body) {
        const data = {};

        const langRegex = new RegExp('.+(' + this.strLangs + ')$', 'g');
        const replaceRegex = new RegExp('(' + this.strLangs + ')$', 'g');

        for (const field in body ) {
            if ( field.match(langRegex) ) {
                const clear = field.replace(replaceRegex, '');
                const lang = field.replace(clear + '_', '');
                data[lang] = {
                    ...data[lang] || {},
                    ...{
                        [clear]: body[field]
                    }
                };
            } else {
                data[field] = body[field];
            }
        }
        return data;
    }

    rename(model, l) {
        const langRegex = new RegExp('/.+(' + this.strLangs + ')$', 'g');

        if ( l ) {
            model = model.replace(langRegex, '') + '_' + l;
        }
        return model;
    }

    get configCountry(): Country {
        const config = this.storage.getItem('country', ParseStorageEnum.json);
        if (config) {
            return config;
        }

        return null;
    }
}
