export interface ApiResponse {
    error: boolean;
    message: string;
    body: any; // [] || {}
    links?: PaginateLinkInterface;
    meta?: PaginateMetaInterface;
}

export interface PaginateLinkInterface {
    first: number;
    last: number;
    prev: number;
    next: number;
}

export class PaginateMetaInterface {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
    sort?: string;
    sortType?: string;
    filters?: string;
}

export enum SortTypeEnum {
    asc = 'asc',
    desc = 'desc'
}
