import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule, NgbCarouselConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import {AuthGuard, CredentialGuard, PublicGuard} from './_guards/auth.guard';
import { AlertService } from './_services/alert.service';
import { AuthService } from './_services/auth.service';

// Routing
import { routing } from './app.routing';

// Components
import { AppComponent } from './app.component';

import { SettingsModule } from './_layout/settings/settings.module';
import { ThemeSettingsConfig } from './_layout/settings/theme-settings.config';
import {menuSettingsConfig, MenuSettingsConfig} from './_layout/settings/menu-settings.config';

import { HeaderComponent } from './_layout/header/header.component';
import { VerticalComponent as HeaderVerticalComponent } from './_layout/header/vertical/vertical.component';
import { HorizontalComponent as HeaderHorizontalComponent } from './_layout/header/horizontal/horizontal.component';
import { FullLayoutNavbarComponent } from './_layout/header/full-layout-navbar/full-layout-navbar.component';
import { FooterComponent } from './_layout/footer/footer.component';
import { NavigationComponent as AppNavigationComponent } from './_layout/navigation/navigation.component';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { NavbarService } from './_services/navbar.service';
import { VerticalnavComponent } from './_layout/navigation/verticalnav/verticalnav.component';
import { HorizontalnavComponent } from './_layout/navigation/horizontalnav/horizontalnav.component';

// perfect scroll bar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// spinner
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { RouterModule } from '@angular/router';
import { CustomizerComponent } from './_layout/customizer/customizer.component';
import { BreadcrumbModule } from './_layout/breadcrumb/breadcrumb.module';
import { DataApiService } from './_services/data.api';
import { HorizontalCustomizerComponent } from './_layout/customizer/horizontal-customizer/horizontal-customizer.component';
import { BlockTemplateComponent } from './_layout/blockui/block-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import {FieldModule} from './_fields/field.module';
import {LangService} from './_services/lang.service';
import {ToolsService} from './_services/tools.service';
import {SearchService} from './_services/search.service';
import {ProfileService} from './_services/profile.service';
import {PasswordConfirmService} from './_services/password-confirm.service';
import {ModalService} from './_services/modal.service';
import {MainInterceptorService} from './_services/main-interceptor.service';
import {FormBuilderService} from './_services/form-builder.service';
import {DataStorageService} from './_services/data-storage.service';
import {CredentialService} from './_services/credential.service';
import {BaseFormService} from './_services/base-form-service';
import {AuthToolService} from './_services/auth-tool.service';
import {ApiResponseErrorService} from './_services/api-response-error.service';
import {NgxNotifierModule, NgxNotifierService} from 'ngx-notifier';
import {NgSelectModule} from '@ng-select/ng-select';
import {ColorPickerModule} from 'ngx-color-picker';
import {TinymceModule} from './_tinymce/tinymce.module';
import {AlertComponent} from './_directives/alert/alert.component';
import {ImageCropperModule} from './_image-cropper/image-cropper.module';
import {ComponentsModule} from './_components/components.module';
import {TableModule} from './_components/table/table.module';
import {PaginateModule} from './_components/paginate/paginate.module';
import {PartialsModule} from './_components/partials/partials.module';
import {MatchHeightModule} from './_components/partials/general/match-height/match-height.module';
import {NgxFontAwesomeModule} from 'ngx-font-awesome';
import {NgxDropzoneModule} from 'ngx-dropzone';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatchHeightModule,
        BreadcrumbModule,
        NgxFontAwesomeModule,
        NgbModule,
        FieldModule,
        FormsModule,
        TinymceModule,
        PartialsModule,
        ImageCropperModule,
        ComponentsModule,
        TableModule,
        PaginateModule,
        ColorPickerModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule, // imports firebase/firestore, only needed for database features
        AngularFireAuthModule, // imports firebase/auth, only needed for auth features
         routing,
        // Settings modules
        SettingsModule.forRoot(ThemeSettingsConfig, menuSettingsConfig),
        PerfectScrollbarModule,
        NgxSpinnerModule,
        NgSelectModule,
        NgxNotifierModule,
        DeviceDetectorModule.forRoot(),
        LoadingBarRouterModule,
        NgxDropzoneModule,
        BlockUIModule.forRoot({
          template: BlockTemplateComponent
        })
    ],
    declarations: [
        AppComponent,
        PublicLayoutComponent,
        PrivateLayoutComponent,
        HeaderComponent,
        FullLayoutNavbarComponent,
        HeaderHorizontalComponent,
        HeaderVerticalComponent,
        FooterComponent,
        AppNavigationComponent,
        AlertComponent,
        VerticalnavComponent ,
        HorizontalnavComponent ,
        CustomizerComponent,
        HorizontalCustomizerComponent,
        BlockTemplateComponent,
        FullLayoutComponent
      ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: MainInterceptorService, multi: true },
        AuthGuard,
        PublicGuard,
        CredentialGuard,
        AlertService,
        NavbarService,
        DataApiService,
        AuthService,
        LangService,
        ToolsService,
        SearchService,
        ProfileService,
        PasswordConfirmService,
        ModalService,
        FormBuilderService,
        DataStorageService,
        CredentialService,
        BaseFormService,
        AuthToolService,
        ApiResponseErrorService,
        NgxNotifierService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig
        },
        NgbCarouselConfig,
        NgbModalConfig,
    ],
    entryComponents: [
      BlockTemplateComponent
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule]
})

export class AppModule {

}
