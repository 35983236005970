import {
    Component, Input,
    OnChanges,
    OnInit, Output, EventEmitter, SimpleChanges
} from '@angular/core';
import {ItemFileListInterface} from '../files-upload.component';

@Component({
    selector: 'file-preview-list',
    templateUrl: './file-preview-list.component.html',
    styleUrls: ['./file-preview-list.component.scss']
})

export class FilePreviewListComponent implements OnInit, OnChanges {

    private _viewItem = false;
    private _deleteItem = false;

    public imagesType = ['png', 'jpeg', 'jpg', 'svg'];
    public videosType = ['mp4', 'webm', 'x-msvideo', 'quicktime', 'avi'];

    @Input() items: ItemFileListInterface[] = [];

// setters

    @Input() set viewItem(bool: boolean) {
        this._viewItem = bool || false;
    }

    @Input() set deleteItem(bool: boolean) {
        this._deleteItem = bool || false;
    }

    @Output() delete = new EventEmitter<ItemFileListInterface>();

    @Output() update = new EventEmitter<ItemFileListInterface>();

    @Output() copy = new EventEmitter<string>();

    @Output() takeView = new EventEmitter<ItemViewListFile>();

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const changeName in changes) {
            const change = changes[changeName];
            const previousValue = JSON.stringify(change.previousValue);
            const currentValue  = JSON.stringify(change.currentValue);

            if (changeName === 'file') {
                if (currentValue !== undefined && currentValue !== null) {
                }
            }
        }
    }

    /** Remove method to be used from the template. */
    doDelete(item: ItemFileListInterface) {
        this.delete.next(item);
    }

    doEdit(item: ItemFileListInterface) {
        this.update.next(item);
    }

    doCopy(str) {
        this.copy.next(str);
    }

    handleView(item: ItemFileListInterface) {
        this.takeView.next({
            file: item.src ? item.src.match(/\.(\w+)\?/)[1] : 'link',
            item: item
        });
    }

    iconType(type): string {

        switch (type) {
            case 'pdf':
                return 'fas fa-file-pdf';
            case 'doc':
            case 'docx':
                return 'fas fa-file-word';
            case 'ppt':
            case 'pptx':
                return 'fas fa-file-powerpoint';
            case 'xlsx':
            case 'xml':
                return 'fas fa-file-csv';
            default:
                return 'fas fa-file-alt';
        }
    }

    // getters

    get viewItem(): boolean {
        return this._viewItem;
    }

    get deleteItem(): boolean {
        return this._deleteItem;
    }

}

export interface ItemViewListFile {
    file: string;
    item: ItemFileListInterface;
}
