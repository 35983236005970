import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ApisRoutes} from '../../_routes/apis-routes';
import {FilesUploadLang} from './files-upload.lang';
import {BaseFormService} from '../../_services/base-form-service';
import {DataStorageService} from '../../_services/data-storage.service';
import {ModalService} from '../../_services/modal.service';
import {NgModalComponent} from '../modal/ng-modal.component';
import {NgxDropzoneChangeEvent, NgxDropzoneComponent} from 'ngx-dropzone';
import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ImageCropperComponent, SizeLoadInterface} from '../../_image-cropper/component/image-cropper.component';
import {ParamInterface, SizeInterface} from '../cropper/img/img.component';
import {environment} from '../../../environments/environment';
import {ItemViewListFile} from './file-preview-list/file-preview-list.component';

@Component({
    selector: 'ng-files-upload',
    templateUrl: './files-upload.component.html',
    styleUrls: ['./files-upload.component.scss']
})

export class FilesUploadComponent implements OnInit, AfterViewInit, OnChanges {

    private _multiple = false;

    private _typeUpload = 'token';

    private _typeFile = 'file';

    private _entity = '';

    private _entityId = '';

    private _acceptFile = 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*';

    public spinnerName = 'file';

    /**
     * Parameters to upload file
     */
    public parametersFile: ParamFileInterface = <ParamFileInterface> {
        maxlength: 1,
        limit: 1
    };

    /**
     * Parameters to upload image
     */
    public parametersImage: ParamInterface = <ParamInterface> {
        accept: [],
        avatar: false,
        dependents: false,
        retinable: false,
        limit: 1
    };

    /**
     * Token in uploadType 'token'
     */
    public token;

    /**
     * Flag to await file input
     */
    public awaitingTo;

    public files: ItemFileInterface[] = [];

    public filesList: ItemFileListInterface[] = [];

    public currentIndex = 0;

    public apis = new ApisRoutes();

    public lang = new FilesUploadLang(this.storage.envLang);

    public doLoading = false;

    public currentFile: any;

    public uploading = false;

    public uploadPercent: number;

    public sizeConfig: SizeLoadInterface = {
        max_height: 1,
        max_width: 1,
        min_height: 0 ,
        min_width: 0
    };

// setters

    @Input() set multiple(bool: boolean) {
        this._multiple = bool || false;
    }

    @Input() set typeUpload(str: string) {
        this._typeUpload = str || 'token';
    }

    @Input() set typeFile(str: string) {
        this._typeFile = str || 'file';
    }

    @Input() set entity(str: string) {
        this._entity = str || '';
    }

    @Input() set entityId(str: any) {
        this._entityId = str || '';
    }

    @Input() set acceptFile(str: any) {
        this._acceptFile = str || 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*';
    }

    @ViewChild('_fileInput', {static: false}) fileInput: ElementRef;
    @ViewChild('dropzone', {static: false}) dropzone: NgxDropzoneComponent;

    @ViewChild(ImageCropperComponent, {static: false}) cropper: ImageCropperComponent;

    /**
     * onCreate event
     */
    @Output() onCreate = new EventEmitter<object>();

    /**
     * onUpdate event
     */
    @Output() onUpdate = new EventEmitter<object>();
    /**
     * onUpdate event
     */
    @Output() onSelect = new EventEmitter<object>();

    /**
     * onDelete event
     */
    @Output() onDelete = new EventEmitter();

    /**
     * onName event
     */
    @Output() onName = new EventEmitter<string>();

    /**
     * takeViewFile event
     */
    @Output() takeViewFile = new EventEmitter<ItemViewListFile>();

    /**
     * File token generated
     */
    @Output() fileTokenGenerated = new EventEmitter<string>();


    private _size = 'p400x400';
    @Input() set size(value: string) {
        this._size = value || 'p400x400';
    }

    private _viewFile = false;
    @Input() set viewFile(bool: boolean) {
        this._viewFile = bool || false;
    }

    private _deleteFile = false;
    @Input() set deleteFile(bool: boolean) {
        this._deleteFile = bool || false;
    }

    dimensions = [];
    imageChangedEvent: any = '';
    resolveCropWait = null;

    /**
     * Rules of images
     */
    public rules: SizeInterface;

    constructor(public base: BaseFormService,
                public storage: DataStorageService,
                public modal: ModalService,
                public http: HttpClient) { }

    ngOnInit() {
        //
    }

    ngAfterViewInit() {
        // First, we set rules and parameters
        // Then we prepare the component

        // this.rulesDataFile();
    }

    rulesDataFile() {

        this.base.get(this.apis.routes.commons.files.rules, {urlParams: {entity: this.entity}, spinnerName: this.spinnerName}).subscribe( resp => {

            switch (this.typeFile) {
                case 'file':
                    this.parametersFile = resp['body']['file']['params'];
                    break;
                case 'image':
                    if (this.size.startsWith('c')) {
                        this.rules = resp['body']['image']['dimensions'][this.size];
                    } else {
                        this.dimensions = resp['body']['image']['dimensions'][this.size];
                    }
                    this.parametersImage = resp['body']['image']['params'];
                    break;
            }
            this.prepare();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const changeName in changes) {
            const change = changes[changeName];
            const previousValue = JSON.stringify(change.previousValue);
            const currentValue  = JSON.stringify(change.currentValue);

            if (changeName === 'typeFile') {
                if (currentValue !== undefined && currentValue !== null) {
                    this.rulesDataFile();
                }
            }

            if (changeName === 'entityId') {
                if (currentValue !== undefined && currentValue !== null) {
                    // this.prepareEntity();
                }
            }
        }
    }

// events

    onSelectInput(event: NgxDropzoneChangeEvent) {
        const files = Array.from(event.addedFiles);

        if (this.onSelect) {
            this.onSelect.emit();
        }

        for (let i = 0; i < files.length; i++) {
            switch (this.typeFile) {
                case 'image':
                    if (this.files.length === this.parametersImage.limit && this.awaitingTo !== 'edit') {
                        break;
                    }
                    break;
                case 'file':
                    if (this.files.length === this.parametersFile.limit && this.awaitingTo !== 'edit') {
                        break;
                    }
                    break;
            }

            if (this.awaitingTo !== 'edit') {
                this.currentIndex = this.generateIndex();
            }

            this.setFiles(files[i], files[i]['name']);

            if (this.awaitingTo === 'edit') {

                this.uploading = false;
                this.awaitingTo = 'new';
                break;
            }

            this.uploading = false;
        }
    }

// actions

    doInput() {
        this.fileInput.nativeElement.click();
    }

// Prepares

    public prepare() {

        switch (this.typeUpload) {
            case 'token':
                // Get file token
                this.base.get(this.apis.routes.commons.token).subscribe( resp => {
                    this.token = resp['body']['token'];
                    this.storage.setItem('_fileToken', this.token);
                    this.fileTokenGenerated.emit(this.token);
                });
                break;
            case 'entity':
                // If entity id is set, prepare
                // Else, await to ngOnChanges to prepare
                if (this.entityId) {
                    this.prepareEntity();
                }
                break;
        }
    }

    public prepareEntity() {
        this.base.get(this.apis.routes.commons.files.entity, {urlParams: {
                entity: this.entity,
                id: this.entityId
            }, spinnerName: this.spinnerName}).subscribe( resp => {
            this.doPrepare(resp);
        });
    }

    public doPrepare(resp) {

        const response = resp['body'];

        switch (this.typeFile) {
            case 'image':
                this.parametersImage = response['parameters']['image']['params'];

                for (let i = 0; i < response['image'].length; i++) {

                    const pushItem = <ItemFileListInterface> {
                        id: response['image'][i]['id'],
                        index: response['image'][i]['index'],
                        src: response['image'][i]['dimensions'][0]['src'],
                        type: response['image'][i]['dimensions'][0]['src'].match(/\.(\w+)\?/)[1]
                    };

                    // Add to file
                    this.filesList.push(pushItem);
                }

                break;
            case 'file':
                this.parametersFile = response['parameters']['file']['params'];

                for (let i = 0; i < response['file'].length; i++) {

                    const pushItem = <ItemFileListInterface> {
                        id: response['file'][i]['id'],
                        index: response['file'][i]['index'],
                        src: response['file'][i]['src'],
                        type: response['file'][i]['src'].match(/\.(\w+)\?/)[1]
                    };

                    // Add to file
                    this.filesList.push(pushItem);
                }
                break;
        }
    }

// methods

    setFiles(file, name) {

        this.currentFile = name;

        this.onName.emit(name);

        // Find if already exists in
        const currentFile = this.files.find((item) => {
            return item.index === this.currentIndex;
        });

        if (currentFile) {
            currentFile.file = file;
            currentFile.name = name;
            if (currentFile.uploaded) {
                currentFile.updating = true;
            }
        } else {
            // file don't exists in files (Is new)
            this.currentIndex = this.generateIndex();

            const pushItem = <ItemFileInterface> {
                index: this.currentIndex,
                name: name,
                file: file
            };

            // Add to file
            this.files.push(pushItem);
        }

        if (this.entityId && this.filesList.length > 0) {

            const toBase64 = item => new Promise<string>((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => resolve(typeof reader.result === 'string' ? reader.result : '');
                reader.onerror = error => reject(error);
                reader.readAsDataURL(item);
            });

            const currentList = this.filesList.find((item) => {
                return item.index === this.currentIndex;
            });

            if (currentList) {
                toBase64(file).then(img => setTimeout(() => currentList.base = img));
            }
        }
    }

    /**
     * Find not used index and return
     */
    protected generateIndex() {
        const range = [];

        switch (this.typeFile) {
            case 'image':
                while (range.length < this.parametersImage.limit) {
                    range.push(range.length + 1);
                }
                break;
            case 'file':
                while (range.length < this.parametersFile.limit) {
                    range.push(range.length + 1);
                }
                break;
        }

        return range.find((item) => {
            return !this.files.find((i) => {
                return i.index === item;
            });
        });
    }

// validators

    // Convert Base64 to File
    protected BlobToFile(file: Blob, contentType?, name?) {
        contentType = contentType || '';
        return new File([file], name ? name : 'public', {'type': contentType});
    }

    /**
     * Is entity multi-image?
     */
    public isMultiFile(): boolean {
        switch (this.typeFile) {
            case 'image':
                return this.parametersImage ? this.parametersImage.limit > 1 : false;
            case 'file':
                return this.parametersFile ? this.parametersFile.limit > 1 : false;
        }

        return false;
    }

    public isTouched(): boolean {
        return this.files.filter((item) => {
            return !item.uploaded || item.updating;
        }).length > 0;
    }

    get parameterLimit(): number {
        switch (this.typeFile) {
            case 'image':
                return this.parametersImage.limit;
            case 'file':
                return this.parametersFile.limit;
        }
        return 0;
    }

    get limitValid(): number {
        switch (this.typeFile) {
            case 'image':
                return this.parametersImage.limit;
            case 'file':
                return this.parametersFile.limit;
        }
        return 0;
    }

// getters

    get multiple() {
        return this._multiple;
    }

    get typeUpload() {
        return this._typeUpload;
    }

    get entity() {
        return this._entity;
    }

    get entityId() {
        return this._entityId;
    }

    get acceptFile() {
        return this._acceptFile;
    }

    get typeFile() {
        return this._typeFile;
    }

    get size() {
        return this._size;
    }

    get viewFile() {
        return this._viewFile;
    }

    get deleteFile() {
        return this._deleteFile;
    }
    // Buttons

    doCopy(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        if (val) {
            this.base._n.notify(this.lang.copy_link, 'darkNoIcon');
        }
    }

    /**
     * Prepare formData to upload
     * @param index only one index upload or all
     */
    public upload(index?: number): Observable<any> {

        const formData = new FormData();
        formData.append('entity', this.entity);

        let toUpload = [];

        if (index) {
            toUpload = this.files.filter((item) => {
                return item.index === index;
            });
        } else {
            toUpload = this.files.filter((item) => {
                return !item.uploaded || item.updating;
            });
        }

        let i = 0;

        switch (this.typeFile) {
            case 'image':

                for (const image of toUpload) {
                    const imgIndex = 'image[' + String(i) + ']';
                    formData.append(imgIndex + '[file]', image['file']);
                    formData.append(imgIndex + '[index]', image['index']);
                    formData.append(imgIndex + '[size]', this.size);
                    i++;
                }
                break;
            case 'file':

                for (const file of toUpload) {
                    const fileIndex = 'file[' + String(i) + ']';
                    formData.append(fileIndex + '[file]', file['file']);
                    formData.append(fileIndex + '[index]', file['index']);
                    i++;
                }
                break;
        }

        return new Observable(Observer => {
            this.doUpload(formData).subscribe(response => {
                Observer.next(response);
                Observer.complete();
            });
        });
    }

    /**
     * Upload formData to API
     */
    public doUpload(formData: FormData): Observable<any> {

        const httpOptions: object = {
            headers: new HttpHeaders({
                'LANGUAGE':  this.storage.getItem('language') || environment.defaultLang || 'en',
                'X-nob-app': 'Api-request',
                Authorization: 'Bearer ' + this.storage.token || null
            }),
            reportProgress: true,
            observe: 'events'
        };

        return new Observable(Observer => {

            switch (this.typeUpload) {
                case 'token':
                    formData.append('token', this.token);
                    this.doLoading = true;

                    this.http.post(this.base.resolve(this.apis.routes.commons.token_files.upload), formData, httpOptions).subscribe(
                        response => {

                        if (response['type'] === HttpEventType.UploadProgress) {
                            this.base.spinner.show(this.spinnerName);
                            this.doLoading = true;

                            this.uploadPercent = Math.round((response['loaded'] / response['total']) * 100);

                        } else if (response['type'] === HttpEventType.Response) {
                            if (response['status'] === 200) {
                                this.doLoading = false;

                                Observer.next(response);

                                Observer.complete();
                                // this.onCreate.emit(response['body']);
                                this.base._n.notify(this.lang.upFile, 'success');
                                this.base.spinner.hide(this.spinnerName);
                            } else {
                                this.base.spinner.hide(this.spinnerName);
                                this.doLoading = false;
                            }
                        } else {
                            // console.log('Event Other: ', event);
                        }
                    }, error => {
                        this.base.spinner.hide(this.spinnerName);

                        Observer.error(error);

                        Observer.complete();
                    });

                    break;

                case 'entity':

                    formData.append('id', this.entityId);
                    this.doLoading = true;

                    this.http.post(this.base.resolve(this.apis.routes.commons.files.upload), formData, httpOptions).subscribe(
                        response => {

                            if (response['type'] === HttpEventType.UploadProgress) {
                                this.base.spinner.show(this.spinnerName);
                                this.doLoading = true;

                                const currentDone = response['loaded'] / response['total'];
                                this.uploadPercent = Math.round((response['loaded'] / response['total']) * 100);

                            } else if (response['type'] === HttpEventType.Response) {
                                if (response['status'] === 200) {

                                    Observer.next(response);

                                    Observer.complete();

                                    this.doLoading = false;
                                    // this.doPrepare(response['body']);
                                    // this.onCreate.emit(response['body']);
                                    this.base._n.notify(this.lang.upFile, 'success');
                                    this.base.spinner.hide(this.spinnerName);
                                } else {
                                    this.base.spinner.hide(this.spinnerName);
                                    this.doLoading = false;
                                }
                            } else {
                                // console.log('Event Other: ', event);
                            }
                        }, error => {
                            this.base.spinner.hide('cropper');

                            Observer.error(error);

                            Observer.complete();
                        });

                    break;

                default:
                    Observer.error();

                    Observer.complete();
                    break;
            }

        });
    }

    // Handlers

    // Handle add new image
    handleNew() {
        this.awaitingTo = 'new';
        this.fileInput.nativeElement.click();
    }

    // Handle edit image
    handleEdit(event: ItemFileListInterface) {
        this.awaitingTo = 'edit';
        this.currentIndex = event.index;
        this.dropzone.showFileSelector();
    }

    // Handle edit image
    handleEditList(event: ItemFileListInterface) {
        this.awaitingTo = 'edit';
        this.currentIndex = event.index;
        this.dropzone.showFileSelector();
    }

    handleCleanList(event: ItemFileListInterface) {
        if (this.filesList.length < this.parameterLimit) {
            const file = this.filesList.find((item) => {
                return item.index === event.index;
            });

            if (file) {
                this.handleDelete(file);
                return;
            }
        }
    }

    handleViewFile(event: ItemViewListFile) {
        this.takeViewFile.emit(event);
    }

    // Handle one image upload
    handleUpload(item: ItemFileInterface) {
        this.upload(item.index);
    }

    handleClean(event: ItemFileListInterface) {
        const file = this.files.find((item) => {
            return item.index === event.index;
        });

        if (file) {
            this.handleDelete(file);
            return;
        }
    }

    // Handle delete image
    handleDelete(item: ItemFileInterface | ItemFileListInterface) {
        const modalRef = this.modal.open(NgModalComponent);
        modalRef.componentInstance.data = this.lang.dataAlert;
        modalRef.result.then((result) => {
            if (result) {

                this.awaitingTo = undefined;

                switch (this.typeUpload) {
                    case 'token':
                        if (item['uploaded']) {

                            let data = {};

                            switch (this.typeFile) {
                                case 'file':
                                    data = {
                                        entity: this.entity,
                                        token: this.token,
                                        type: 'file',
                                        index: item.index
                                    };
                                    break;
                                case 'image':
                                    data = {
                                        entity: this.entity,
                                        token: this.token,
                                        type: 'image',
                                        size: this.size,
                                        index: item.index
                                    };
                                    break;
                            }

                            this.base.del(this.apis.routes.commons.token_files.del, data, {
                                spinnerName: this.spinnerName
                            }).subscribe( resp => {
                                const image = this.files.findIndex((img) => {
                                    return img.index === item.index;
                                });
                                this.files.splice(image, 1);
                            });
                        } else {
                            const image = this.files.findIndex((img) => {
                                return img.index === item.index;
                            });

                            this.files.splice(image, 1);
                        }
                        break;
                    case 'entity':
                        if (item['uploaded']) {

                            this.base.del(this.apis.routes.commons.files.del, item.id, {
                                spinnerName : this.spinnerName
                                }
                            ).subscribe( resp => {
                                const image = this.files.findIndex((img) => {
                                    return img.id === item.id;
                                });
                                this.files.splice(image, 1);
                                this.onDelete.emit();
                            });
                        } else {
                            const image = this.files.findIndex((img) => {
                                return img.index === item.index;
                            });
                            this.files.splice(image, 1);
                        }
                        break;
                }

                this.currentIndex = this.generateIndex();
                this.currentFile = undefined;
                this.uploading = false;
            }
        }).catch((result) => {
            console.log('Dismissed', result);
        });
    }

}

export class ItemFileInterface {
    id?: number;
    index: number;
    name?: string;
    src?: string;
    original: string;
    uploaded: boolean;
    updating: boolean;
    file?: File;
}

export class ItemFileListInterface {
    id?: number;
    index: number;
    src?: string;
    type?: string;
    base?: string;
}

export interface ParamFileInterface {
    maxlength: number;
    limit: number;
}
