import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthToolService} from '../_services/auth-tool.service';
import {Observable} from 'rxjs';
import {CredentialService} from '../_services/credential.service';

export class BaseCanActivate implements CanActivate {

    public validate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return true;
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean>|Promise<boolean>|boolean {
        return this.validate(route, state);
    }
}

@Injectable()
export class AuthGuard extends BaseCanActivate {

    constructor(private router: Router, private auth: AuthToolService) {
        super();
    }

    validate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.auth.isLog) {
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}

@Injectable()

export class PublicGuard extends BaseCanActivate {

    constructor(public auth: AuthToolService, private router: Router) {
        super();
    }

    validate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.auth.isLog) {
            return true;
        }

        // logged in so redirect to dashboard page with the return url
        this.router.navigate(['/dashboard'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}

@Injectable()

export class CredentialGuard extends BaseCanActivate {

    constructor(public auth: AuthToolService,
                private route: Router,
                public credential: CredentialService) {
        super();
    }

    validate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if ( this.auth.isLog ) {
            return this.credential.checkCredential(route.data.credentials);
        }

        // not logged in so redirect to login page with the return url
        this.route.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
