<!-- main app container -->
<ngx-loading-bar [includeSpinner]='false' height='3px' color="#FF4961"></ngx-loading-bar>

<ngx-notifier [allowDuplicates]="false"
              [allowHTML]="true"
              [className]="'notify'"
              [duration]="4000"
              [dismissOnClick]="false"
              [insertOnTop]="true"
              [max]="4">
</ngx-notifier>
<router-outlet *ngIf="showContent"></router-outlet>
<!-- credits -->
