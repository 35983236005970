import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ImageCropperModule} from '../_image-cropper/image-cropper.module';
import {ReactiveFormsModule} from '@angular/forms';
import {DividerComponent} from './divider/divider.component';
import {SubstrPipe} from '../_pipes/substr.pipe';
import {SanitizeHtmlPipe} from '../_pipes/sanitize-html.pipe';
import {ToHtmlPipe} from '../_pipes/to-html.pipe';
import {UnixDatePipe} from '../_pipes/unix-date.pipe';
import {UnixTimePipe} from '../_pipes/unix-timeme.pipe';
import {CredentialDirective} from '../_directives/credential.directive';
import {PrivateImageComponent} from './private-image/private-image.component';
import {PictureImageComponent} from './picture-image/picture-image.component';
import {ImgComponent} from './cropper/img/img.component';
import {ImgCropperComponent} from './cropper/img-c/img-cropper.component';
import {SelectImgImageComponent} from './cropper/select-img/select-img.component';
import {SelectItemImageComponent} from './cropper/select-img/select-item/select-item.component';
import {LY_THEME, LY_THEME_GLOBAL_VARIABLES, LyThemeModule} from '@alyle/ui';
import {LyImageCropperModule} from '@alyle/ui/image-cropper';
import {LyIconModule} from '@alyle/ui/icon';
import {LyButtonModule} from '@alyle/ui/button';
import {MinimaLight} from '@alyle/ui/themes/minima';
import {GlobalVariables} from './cropper/img-c/style';
import {NgSearchComponent} from './ng-search/ng-search.component';
import {MessageWarningComponent} from './message-warning/message-warning.component';
import {MapLocationComponent} from './map-location/map-location.component';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../../environments/environment';
import {FilePreviewComponent} from './files-upload/file-preview/file-preview.component';
import {FilesUploadComponent} from './files-upload/files-upload.component';
import {FileViewBadgeComponent} from './files-upload/file-view-badge/file-view-badge.component';
import {FilePreviewListComponent} from './files-upload/file-preview-list/file-preview-list.component';
import {FileEditBadgeComponent} from './files-upload/file-edit-badge/file-edit-badge.component';
import {FileCopyBadgeComponent} from './files-upload/file-copy-badge/file-copy-badge.component';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {FilesUploadSimpleComponent} from './files-upload/files-upload-simple/files-upload-simple.component';
import {RatingComponent} from './rating/rating.component';
import {MeterToKilometerPipe} from '../_pipes/meter-to-kilometer.pipe';
import {SecondsToMinutesPipe} from '../_pipes/seconds-to-minutes.pipe';


@NgModule({
    declarations: [
        DividerComponent,
        SubstrPipe,
        SanitizeHtmlPipe,
        ToHtmlPipe,
        UnixDatePipe,
        UnixTimePipe,
        MeterToKilometerPipe,
        SecondsToMinutesPipe,
        CredentialDirective,
        PrivateImageComponent,
        PictureImageComponent,
        ImgComponent,
        SelectImgImageComponent,
        SelectItemImageComponent,
        NgSearchComponent,
        MessageWarningComponent,
        MapLocationComponent,
        FilePreviewComponent,
        FilesUploadComponent,
        FileViewBadgeComponent,
        FilePreviewListComponent,
        FileEditBadgeComponent,
        FileCopyBadgeComponent,
        ImgCropperComponent,
        FilesUploadSimpleComponent,
        RatingComponent
    ],
    exports: [
        DividerComponent,
        SubstrPipe,
        SanitizeHtmlPipe,
        ToHtmlPipe,
        UnixDatePipe,
        UnixTimePipe,
        MeterToKilometerPipe,
        SecondsToMinutesPipe,
        CredentialDirective,
        PrivateImageComponent,
        PictureImageComponent,
        ImgComponent,
        SelectImgImageComponent,
        SelectItemImageComponent,
        NgSearchComponent,
        MessageWarningComponent,
        MapLocationComponent,
        FilePreviewComponent,
        FilesUploadComponent,
        FileViewBadgeComponent,
        FilePreviewListComponent,
        FileEditBadgeComponent,
        FileCopyBadgeComponent,
        ImgCropperComponent,
        FilesUploadSimpleComponent,
        RatingComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        NgxSpinnerModule,
        ImageCropperModule,
        ReactiveFormsModule,
        LyThemeModule.setTheme('minima-light'),
        LyImageCropperModule,
        LyButtonModule,
        LyIconModule,
        NgxDropzoneModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleApiKey,
            libraries: ['places']
        }),
    ],
    providers: [
        {
            provide: LY_THEME,
            useClass: MinimaLight
        },
        {
            provide: LY_THEME_GLOBAL_VARIABLES,
            useClass: GlobalVariables
        },
    ],
})
export class ComponentsModule {}
