import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ValidateSchemas} from '../../_fields/validate.schema';
import {FormControl, FormGroup} from '@angular/forms';
import {SearchService} from '../../_services/search.service';
import {DataStorageService} from '../../_services/data-storage.service';
import { NgSearchLang } from './ng-search.lang';

@Component({
  selector: 'ng-search',
  templateUrl: './ng-search.component.html',
  styleUrls: ['./ng-search.component.scss']
})
export class NgSearchComponent extends ValidateSchemas implements OnInit, AfterViewChecked  {
  public group: FormGroup;
  public searchDone: boolean;
  public isHeaderSearchOpen: boolean;
  public lang = new NgSearchLang(this.storage.envLang);
  
  constructor(public cd: ChangeDetectorRef, public sSearch: SearchService, public storage: DataStorageService) {
    super();
    this.group = new FormGroup({search: new FormControl(null, [])});
    this.searchDone = false;
    this.isHeaderSearchOpen = false;
  }


  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  public searchingDoClick() {

    if (!this.isHeaderSearchOpen) {
      this.isHeaderSearchOpen = true;
      return;
    }

    if (this.searchDone) {
        this.group.get('search').setValue('');
        this.sSearch.doChange(this.group.value['search']);
        this.searchDone = false;
        this.isHeaderSearchOpen = false;
        return;
    }

    if (!this.searchDone) {
      this.searchDone = true;
      this.sSearch.doChange(this.group['value']['search']);
      return;
    }
  }

  public searchingDoEnter(): void {
    this.searchDone = true;
    this.sSearch.doChange(this.group['value']['search']);
  }
}
