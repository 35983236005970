import {Component, OnInit, OnChanges, Input, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ToolsService} from '../_services/tools.service';
import {FormBuilderService} from '../_services/form-builder.service';
import {DataStorageService} from '../_services/data-storage.service';
import {ValidateSchemas} from './validate.schema';
import {upToDown} from '../_animations/ease-in-out';

@Component({
    selector: 'input-field',
    templateUrl: './field.component.html',
    styleUrls: ['./field.component.scss'],
    inputs: ['title', 'model'],
    providers : [FormBuilderService],
    animations : [upToDown]

})

export class FieldComponent extends ValidateSchemas implements OnInit, OnChanges {

    constructor(
        public tools: ToolsService,
        public fb: FormBuilderService,
        public storage: DataStorageService,
        public cd: ChangeDetectorRef
    ) {
        super();
        this.validator = 'text';
    }

    public errors: any[] = [];

    @Input() label;

    @Input() placeHolder;

    @Input() model;

    @Input() group: FormGroup;

    @Input() validator: string;

    @Input() inputType = 'text';

    @Input() placeholder = '';

    @Input() required: boolean;

    @Input() disabled: boolean;

    @Input() form_dynamic = true;

    @Input() submitted: boolean;

    @Output() modelChange = new EventEmitter();

    public type = 'text';

    public oldModelValue: any;

    @ViewChild('input', {static: false}) input: ElementRef;

    actionTrigger() {
        this.errors = this.fb.setErrorMessage(
            this.label,
            this.group.controls[this.model].errors
        );
    }

    get isValid() {
        return this.group.controls[this.model].valid;
    }

    get errorEmpty() {
        return this.errors.length === 0;
    }

    ngOnInit() {
        this.toDisabled(this.disabled);
    }

    ngOnChanges(changes) {

        if (changes['disabled']) {
            if (changes['disabled']['previousValue'] !== undefined) {
                this.toDisabled(this.disabled);
             }
        }

        if (this.required) {
            this.validatorType[this.validator].validators.push(['required', this.required]);
        }

        if (this.submitted) {
            this.group.controls[this.model].markAsTouched();
            this.group.controls[this.model].markAsDirty();
            this.actionTrigger();
        }

        if (this.model) {
            this.group.addControl(
                this.model,
                this.fb.validatorParams(this.validatorType[this.validator].validators)
            );
        }
        this.type = this.inputType ? this.validatorType[this.inputType].type : 'text';
    }

    public toDisabled(action: boolean): void {
        if (this.model) {
            if (action) {
                this.group.get(this.model).disable();
            } else {
                this.group.get(this.model).enable();
            }
        }
    }


}
