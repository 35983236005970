import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {DataStorageService, ParseStorageEnum} from './data-storage.service';

@Injectable()
export class CredentialService {

    private credentials: Array<string>;

    private loggedIn: boolean;

    // event reload
    private reload = new Subject();
    public reloadCredential = this.reload.asObservable();

    constructor(public storage: DataStorageService) {
        this.credentials = this.storage.getItem('myCredentials', ParseStorageEnum.json) || [];
        this.loggedIn = !!this.storage.getItem('access_token');
    }

    public setCredentials(arr: Array<string>): void {

        this.credentials = arr;
        this.loggedIn = true;
    }

    public checkCredential(arr: Array<string> | Array<Array<string>>| undefined, additional?: boolean): boolean {

        if (!arr) {
            return !this.loggedIn;
        }

        if (this.credentials.length === 0 || arr.length === 0) {
            return false;
        }

        if (additional !== undefined) {
            if (additional === false) {
                return false;
            }
        }

        if (this.isGroupCredentialArray(arr)) {
            return this.checkGroupCredential(arr);
        }

        return this.accessGranted(arr);
    }

    private isGroupCredentialArray(arr: any): boolean {
        return typeof  arr[0] !== 'string';
    }

    private checkGroupCredential(arr: Array<any>): boolean {
        for (const item of arr) {
            if (this.accessGranted(item)) {
                return true;
            }
        }
        return false;
    }


    private accessGranted(arr: Array<any>): boolean {

        for (const item of arr) {
            if (!this.credentials.includes(item)) {
                return false;
            }
        }
        return true;
    }

    public removeCredentials(): void {
        this.credentials = [];
        this.loggedIn = false;
    }

    public doReloadCredentials() {
        this.reload.next();
    }

}
