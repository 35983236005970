

<div class="item-image" *ngIf="imagesType.includes(file?.type)">
    <img [src]="sanitizedSVGSrc ? sanitizedSVGSrc : imageSrc" [alt]="item.name" />
    <ngx-dropzone-label class="dropzone1">{{ item.name }}</ngx-dropzone-label>
    <ngx-dropzone-remove-badge *ngIf="removable" (click)="doDelete($event)"></ngx-dropzone-remove-badge>
    <file-edit-badge *ngIf="removable" (click)="doEdit()"></file-edit-badge>
</div>

<div class="item-video" *ngIf="videosType.includes(file?.type)">
    <video *ngIf="sanitizedVideoSrc" controls (click)="$event.stopPropagation()">
        <source [src]="sanitizedVideoSrc" />
    </video>
    <ngx-dropzone-label class="dropzone1">{{ item.name }}</ngx-dropzone-label>
    <ngx-dropzone-remove-badge *ngIf="removable" (click)="doDelete($event)"></ngx-dropzone-remove-badge>
    <file-edit-badge *ngIf="removable" (click)="doEdit()"></file-edit-badge>

</div>

<div class="item-file" *ngIf="!imagesType.includes(file?.type) && !videosType.includes(file?.type)">
    <ngx-dropzone-label class="dropzone1">{{ item.name }}</ngx-dropzone-label>
    <ngx-dropzone-remove-badge *ngIf="removable" (click)="doDelete($event)"></ngx-dropzone-remove-badge>
    <file-edit-badge *ngIf="removable" (click)="doEdit()"></file-edit-badge>
</div>
