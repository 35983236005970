import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { Tinymce } from './tinymce';

@Directive({
  selector: 'mce-toolbar',
  inputs: ['toolbar']
})
export class MceToolbar implements AfterViewInit {

  @Input() toolbar: string | string[] | null = null;

  constructor(private _elem: ElementRef, private _mce: Tinymce) {}

  ngAfterViewInit(): void {

    if ( this.toolbar && this.toolbar.length > 0 ) {
      this._mce.setToolbar(this.toolbar);
    }

  }

}
