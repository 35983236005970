import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import {Observable, Subject} from 'rxjs';
import {ApiResponseErrorService} from './api-response-error.service';
import {AuthToolService} from './auth-tool.service';
import {AlertService} from './alert.service';
import {ApiResponse} from '../_feature/api-response';

@Injectable()

export class BaseFormService {

    // event reload
    private reload = new Subject();
    public reloadMethod = this.reload.asObservable();

    constructor(public auth: AuthToolService,
                public route: Router,
                public _n: AlertService,
                public http: HttpClient,
                public err: ApiResponseErrorService,
                public spinner: NgxSpinnerService) { }

    public urlResolve;

// methods

    public post(url: string, data,
                params: RequestParamsInterface = {}): Observable<ApiResponse> {

        return new Observable(Observer => {

            this.http.post(this.resolve(url, params.urlParams),
                data).subscribe((response: ApiResponse) => {

                    Observer.next(response);

                    Observer.complete();

                    this.spinner.hide(params.spinnerName);

                    if (params.msg) {
                        this._n.notify(response['message'], 'success');
                    }

                    if (params.navigate) {

                        this.navigate(params.navigate);
                    }
                }, (error) => {

                    this.spinner.hide(params.spinnerName);

                    this.err.catchHttpError(error);

                    Observer.error(error);

                    Observer.complete();
                }
            );
        });
    }

    public put(url: string, data, params: RequestParamsInterface = {}): Observable<ApiResponse> {

        return new Observable(Observer => {

            this.http.put(this.resolve(url, params.urlParams),
                data).subscribe((response: ApiResponse) => {

                Observer.next(response);

                Observer.complete();

                this.spinner.hide(params.spinnerName);

                if (params.msg) {
                    this._n.notify(response['message'], 'success');
                }

                if (params.navigate) {

                    this.navigate(params.navigate);
                }
            }, (error) => {
                this.spinner.hide(params.spinnerName);
                this.err.catchHttpError(error);

                Observer.error(error);
            });
        });
    }

    public del(url: string, data, params: RequestParamsInterface = {}): Observable<ApiResponse> {

        return new Observable(Observer => {

            const obj = typeof data === 'object' ? data : {id : data};
            // const showMessage = params.msg ? true : false;

            this.http.delete(this.resolve(url, {}, params.special),
                {params : obj}).subscribe((response: ApiResponse) => {

                Observer.next(response);

                Observer.complete();

                this.spinner.hide(params.spinnerName);

                if (params.msg) {
                    this._n.notify(response['message'], 'success');
                }

                if (params.navigate) {
                    this.navigate(params.navigate);
                }
            }, (error) => {
                this.spinner.hide(params.spinnerName);
                this.err.catchHttpError(error);

                Observer.error(error);
            });
        });
    }

    public get(url: string, params: RequestParamsInterface = {}): Observable<ApiResponse> {

        return new Observable(Observer => {

            this.http.get(this.resolve(url, params.urlParams, params.special)).subscribe((response: ApiResponse) => {

                Observer.next(response);

                Observer.complete();

                if (params.msg) {
                    this._n.notify(response['message'], 'success');
                }
                this.spinner.hide(params.spinnerName);
            }, (error) => {
                this.spinner.hide(params.spinnerName);
                this.err.catchHttpError(error);

                Observer.error(error);
            });
        });
    }

// tools

    public resolve(route: string, params?: object, http?: boolean) {

        if (http) {
            this.urlResolve = route.toString();
        } else {
            this.urlResolve = this.auth.apiUrl + route.toString();
        }

        return this.urlResolve + this.serialize(params);
    }

    private paramPush(obj: object, k, separator = '&') {
        const newParams = [];

        let params = [obj[k]];

        let key = k;

        if ( obj[k] instanceof Array ) {

            key = k + '[]';

            params = obj[k];
        }

        for ( const v of params ) {
            newParams.push(key + '=' + encodeURIComponent(v));
        }

        return newParams.join(separator);
    }

    private serialize(obj, join?: boolean) {

        const paramPushAlias = this.paramPush;

        if (obj) {
            return (join ? '&' : '?') + Object.keys(obj)
                .reduce(function(a, k) {
                    a.push(paramPushAlias(obj, k));
                    return a;
                }, []).join('&');
        }

        return '';
    }

    public navigate(route: string, params?: Array<any>) {
        let url = '';

        if (params) {
            params.forEach(function (f) {
                    url += '/' + f;
                }
            );
        }

        this.route.navigateByUrl(route.toString() + url);
    }

    private findEnum(_enum, _string): any {

        for ( const key in _enum ) {

            if ( _enum[key] === _string ) {
                return key;
            }
        }
        return null;
    }

    // method reload

    public doReload() {
        this.reload.next();
    }
}

export interface RequestParamsInterface {
    msg?: boolean;
    navigate?: string;
    spinnerName?: string;
    urlParams?: object;
    special?: boolean;
}
