import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { Tinymce } from './directives/tinymce';
import { McePlugin } from './directives/mce-plugin';
import { MceToolbar } from './directives/mce-toolbar';

export function coreDirectives() {
  return [
    Tinymce, McePlugin, MceToolbar
  ];
}

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: coreDirectives(),
  exports: coreDirectives()
})
export class TinymceModule {}
