import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {NgxNotifierService} from 'ngx-notifier';

@Injectable()
export class AlertService {
    constructor( private _notify: NgxNotifierService) { }

    public notify(message: string, type?: string) {

        let style = StyleNotify.lightNoIcon;

        if (type && StyleNotify[type] ) {
            style = StyleNotify[type];
        }

        this._notify.createToast('<i class="' + style[1] + ' mr-1"></i>' + message, style[0]);

    }
}

export const StyleNotify  = {
    success : ['success', 'fas fa-check'],
    primary : ['primary', 'fas fa-lightbulb'],
    secondary : ['secondary', 'fas fa-play'],
    danger : ['danger', 'fas fa-times'],
    warning : ['warning', 'fas fa-bell'],
    info : ['info', 'fas fa-lightbulb'],
    light : ['light', 'fas fa-bullhorn'],
    dark : ['dark', 'fas fa-bullhorn'],
    darkNoIcon : ['dark', 'noicon'],
    lightNoIcon : ['dark', 'noicon'],
    infoNoIcon : ['dark', 'noicon']
};

