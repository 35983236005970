<th scope="col"
    [ngClass]="{'initial-hover': sorteable,
                'no-sort': !sortType,
                'hover-sort': sortType
                }">
    <div (click)="sorteable && toSort()">

        <span>{{value}}</span>

        <i [ngClass]="{
                        'hover-set': true,
                        'fas fa-chevron-up': sortType === 'desc' && currentSort !== _sortKey,
                        'fas fa-chevron-down': sortType === '' && (!currentSort || currentSort !== _sortKey || currentSort === _sortKey),
                        'color-gray': true
                        }"></i>

        <span *ngIf="_sortKey && sortType">
            <i class="ml-1" [ngClass]="iconType"></i>
        </span>

    </div>
</th>
