import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PaginateMetaInterface} from '../../_feature/api-response';

@Component({
    selector: 'ng-paginate',
    templateUrl: './ng-paginate.component.html',
    styleUrls: ['./ng-paginate.component.scss']
})

export class NgPaginateComponent implements OnInit, OnChanges {

    public pages: Array<number> = [];

    @Input() paginate: PaginateMetaInterface;

    @Output() loadApi = new EventEmitter<number>();

    @Output() loadPaginate = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const changeName in changes) {
            const change = changes[changeName];
            const previousValue = change.previousValue;
            const currentValue  = change.currentValue;

            if (changeName === 'paginate') {
                if (currentValue !== undefined && currentValue !== null) {
                    this.configPages();
                }

                if ((previousValue && currentValue === null) || (previousValue === undefined && currentValue === undefined)) {
                    this.loadApi.emit(1);
                }
            }
        }
    }

    configPages() {
        this.pages = [];
        for (let i = 1; i <= this.paginate.last_page; i++) {
            this.pages.push(i);
        }
    }

    nextPage(page?: number) {
        this.loadPaginate.emit(page);
        this.loadApi.emit(page);
    }

    prevPage() {
        let nPage: number = this.paginate.current_page;

        if (this.paginate.current_page >= 2) {

            nPage = nPage - 1;

            this.loadApi.emit(nPage);
        }
    }

    bestPage(): number {
        if (this.paginate.to === 1 && this.paginate.current_page === this.paginate.last_page) {
            if (this.paginate.current_page > 1) {
                return this.paginate.current_page - 1;
            }
        }

        return this.paginate.current_page;
    }

    bestReload() {
        this.loadApi.emit(this.bestPage());
    }
}
