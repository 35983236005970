import {
    Component, Input,
    OnChanges,
    OnInit, Output, EventEmitter, SimpleChanges, HostBinding, OnDestroy
} from '@angular/core';
import {NgxDropzonePreviewComponent} from 'ngx-dropzone';
import {ItemFileInterface} from '../files-upload.component';
import {SafeStyle, SafeUrl} from '@angular/platform-browser';

@Component({
    selector: 'file-preview',
    templateUrl: './file-preview.component.html',
    styleUrls: ['./file-preview.component.scss'],
    providers: [
        {
            provide: NgxDropzonePreviewComponent,
            useExisting: FilePreviewComponent
        }
    ]
})

export class FilePreviewComponent extends NgxDropzonePreviewComponent implements OnInit, OnChanges, OnDestroy {

    public imagesType = ['image/png', 'image/jpeg', 'image/jpg'];
    public videosType = ['video/mp4', 'video/webm', 'video/x-msvideo', 'video/quicktime'];
    public svgType = 'image/svg+xml';

    @Input() item: ItemFileInterface;

    @Output() delete = new EventEmitter<ItemFileInterface>();

    @Output() update = new EventEmitter<ItemFileInterface>();

    /** The image data source. */
    defaultImgLoading = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBzdHlsZT0ibWFyZ2luOiBhdXRvOyBiYWNrZ3JvdW5kOiByZ2IoMjQxLCAyNDIsIDI0Mykgbm9uZSByZXBlYXQgc2Nyb2xsIDAlIDAlOyBkaXNwbGF5OiBibG9jazsgc2hhcGUtcmVuZGVyaW5nOiBhdXRvOyIgd2lkdGg9IjIyNHB4IiBoZWlnaHQ9IjIyNHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQiPgo8Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSIxNCIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2U9IiM4NWEyYjYiIHN0cm9rZS1kYXNoYXJyYXk9IjIxLjk5MTE0ODU3NTEyODU1MiAyMS45OTExNDg1NzUxMjg1NTIiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+CiAgPGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGR1cj0iMS4xNjI3OTA2OTc2NzQ0MTg0cyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIGtleVRpbWVzPSIwOzEiIHZhbHVlcz0iMCA1MCA1MDszNjAgNTAgNTAiPjwvYW5pbWF0ZVRyYW5zZm9ybT4KPC9jaXJjbGU+CjxjaXJjbGUgY3g9IjUwIiBjeT0iNTAiIHI9IjEwIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZT0iI2JiY2VkZCIgc3Ryb2tlLWRhc2hhcnJheT0iMTUuNzA3OTYzMjY3OTQ4OTY2IDE1LjcwNzk2MzI2Nzk0ODk2NiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjE1LjcwNzk2MzI2Nzk0ODk2NiIgZmlsbD0ibm9uZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgZHVyPSIxLjE2Mjc5MDY5NzY3NDQxODRzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIga2V5VGltZXM9IjA7MSIgdmFsdWVzPSIwIDUwIDUwOy0zNjAgNTAgNTAiPjwvYW5pbWF0ZVRyYW5zZm9ybT4KPC9jaXJjbGU+CjwhLS0gW2xkaW9dIGdlbmVyYXRlZCBieSBodHRwczovL2xvYWRpbmcuaW8vIC0tPjwvc3ZnPg==';
    imageSrc: any = this.sanitizer.bypassSecurityTrustUrl(this.defaultImgLoading);


    /** The video data source. */
    sanitizedVideoSrc: SafeUrl;
    sanitizedSVGSrc: SafeUrl;

    private videoSrc: string;
    private svgSrc: string;

    ngOnInit() {
        this.renderFile();
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const changeName in changes) {
            const change = changes[changeName];
            const previousValue = JSON.stringify(change.previousValue);
            const currentValue  = JSON.stringify(change.currentValue);

            if (changeName === 'file') {
                if (currentValue !== undefined && currentValue !== null) {
                    this.renderFile();
                }
            }
        }
    }

    /** We use the HostBinding to pass these common styles to child components. */
    @HostBinding('style')
    get hostLFileStyle(): SafeStyle {
        const styles = `
			display: flex;
			height: 140px;
			justify-content: center;
			align-items: center;
			margin: 10px;
			border-radius: 5px;
			position: relative;
		`;

        return this.sanitizer.bypassSecurityTrustStyle(styles);
    }

    /** Remove method to be used from the template. */
    doDelete(event) {
        event.stopPropagation();
        this.remove();
    }

    doEdit() {
        this.update.next(this.item);
    }

    /** Remove the preview item (use from component code). */
    remove() {
        if (this._removable) {
            this.delete.next(this.item);
        }
    }

    private renderFile() {

        if (this.imagesType.includes(this.file.type)) {
            this.readFile()
                .then(img => setTimeout(() => this.imageSrc = img))
                .catch(err => console.error(err));
        } else if (this.videosType.includes(this.file.type)) {
            this.videoSrc = URL.createObjectURL(this.file);
            this.sanitizedVideoSrc = this.sanitizer.bypassSecurityTrustUrl(this.videoSrc);
        } else if ( this.file.type === this.svgType) {
            this.svgSrc = URL.createObjectURL(this.file);
            this.sanitizedSVGSrc = this.sanitizer.bypassSecurityTrustUrl(this.svgSrc);
        }
    }

    ngOnDestroy() {
        URL.revokeObjectURL(this.videoSrc);
    }

}
