<div class="modal-header">
    <h4 class="modal-title">{{data.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('cancel')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>{{data.content}}</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">{{data.cancel}}</button>
    <button type="button" class="btn btn-{{ink}}" (click)="modal.close('ok')">{{data.ok}}</button>
</div>
