
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {FieldComponent} from './field.component';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CheckBoxComponent} from './check/check-box.component';
import {DivisibleValidatorDirective} from './validators.directive';
import {RadiosComponent} from './radios/radios.component';
import {SelectComponent} from './select/select.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {TextAreaComponent} from './text-area/text-area.component';
import {FieldCurrencyComponent} from './currency/field-currency.component';
import {CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskDirective} from './currency/currency-mask.directive';
import {DateComponent} from './date/date.component';
import {ColorComponent} from './color/color.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {TinymceModule} from '../_tinymce/tinymce.module';
import {FieldTinymceComponent} from './tinymce/field-tinymce.component';
import {SelectSearchComponent} from './select-search/select-search.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {FieldPhoneComponent} from './field-phone/field-phone.component';
import {WithBtnComponent} from './with-btn/with-btn.component';
import {FieldPhoneCountryComponent} from './field-phone-country/field-phone-country.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {InputMaskModule} from '@ngneat/input-mask';
import {FieldMaskComponent} from './field-mask/field-mask.component';
import {FieldRatingComponent} from "./rating/field-rating.component";

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: 'right',
    allowNegative: false,
    allowZero: false,
    decimal: ',',
    precision: 2,
    prefix: 'R$ ',
    suffix: '',
    thousands: '.',
    nullable: false
};


@NgModule({
    declarations: [
        FieldComponent,
        CheckBoxComponent,
        DivisibleValidatorDirective,
        RadiosComponent,
        SelectComponent,
        TextAreaComponent,
        FieldCurrencyComponent,
        CurrencyMaskDirective,
        DateComponent,
        ColorComponent,
        FieldTinymceComponent,
        SelectSearchComponent,
        FieldPhoneComponent,
        WithBtnComponent,
        FieldPhoneCountryComponent,
        FieldMaskComponent,
        FieldRatingComponent
    ],
    imports: [
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule,
        CommonModule,
        NgxIntlTelInputModule,
        NgbModule,
        ColorPickerModule,
        TinymceModule,
        NgxSpinnerModule,
        InputMaskModule
    ],
    providers: [
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    ],
    exports : [
        FieldComponent,
        CheckBoxComponent,
        DivisibleValidatorDirective,
        RadiosComponent,
        SelectComponent,
        TextAreaComponent,
        FieldCurrencyComponent,
        CurrencyMaskDirective,
        DateComponent,
        ColorComponent,
        FieldTinymceComponent,
        SelectSearchComponent,
        FieldPhoneComponent,
        WithBtnComponent,
        FieldPhoneCountryComponent,
        FieldMaskComponent,
        FieldRatingComponent
    ],
    bootstrap: []})

export class FieldModule { }
