export class FieldLang  implements  FieldInterface {

    errors = {

        required : 'it is required',

        pattern : 'not valid',

        minlength : 'must have less less ::required characters',

        maxlength : 'must have less than ::required characters',

        min : 'must be greater than ::min',

        max : 'must be less than ::max',

        matDatepickerMin : 'invalid date',

        matDatepickerMax : 'invalid date',

        divisible: 'must divisible by ::divisible'
    };

    public constructor(private lang: string) {
        return this[lang] || this;
    }

    es: FieldInterface = {

        errors : {

            required : 'es obligatorio',

            pattern : 'no valido',

            minlength : 'debe tener al menos ::required caracteres',

            maxlength : 'debe tener menos de ::required caracteres',

            min : 'debe ser mayor o igual a ::min',

            max : 'debe ser menor o igual a ::max',

            matDatepickerMin : 'fecha invalida',

            matDatepickerMax : 'fecha invalida',

            divisible: 'debe ser divisible por ::divisible'
        }
    };

    pt: FieldInterface = {
        errors : {

            required : 'é necessário',

            pattern : 'inválido',

            minlength : 'deve ter menos ::caracteres obrigatórios',

            maxlength : 'deve ter menos de ::caracteres obrigatórios',

            min : 'deve ser maior que ::min',

            max : 'deve ser menor que ::max',

            matDatepickerMin : 'data inválida',

            matDatepickerMax : 'data inválida',

            divisible: 'deve ser divisível por ::divisível'
        },
    };
}

export interface FieldInterface {

    errors: {

        required: string;

        pattern: string;

        minlength: string;

        maxlength: string;

        min: string;

        max: string;

        matDatepickerMin: string;

        matDatepickerMax: string;

        divisible: string;
    };
}
