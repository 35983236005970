export class NgModalLang implements ModalContentInterface {

    title = 'Warning';
    cancel = 'Cancel';
    ok = 'Accept';
    content = 'Are you sure ?';

    public constructor(private lang: string) {
        return this[lang] || this;
    }

    es: ModalContentInterface = {
        title: 'Precaución',
        cancel: 'Cancelar',
        ok: 'Aceptar',
        content : 'Estas Seguro ?'
    };

    pt: ModalContentInterface = {
        title: 'Aviso',
        cancel: 'Cancelar',
        ok: 'Aceitar',
        content: 'Tem certeza ?',
    };
}



export interface ModalContentInterface {
    title: string;
    cancel: string;
    ok: string;
    content: string;
}
