import { Injectable } from '@angular/core';
import {DataStorageService} from './data-storage.service';
import {Subject} from 'rxjs';

@Injectable()
export class ProfileService {

    // event reload
    private reload = new Subject();
    public reloadProfile = this.reload.asObservable();

    constructor(
        private storage: DataStorageService
    ) {}

    // method reload

    public doReloadProfile() {
        this.reload.next();
    }

}
