import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgListComponent} from './ng-list.component';
import {PaginateModule} from '../paginate/paginate.module';
import {NgThComponent} from './ng-th/ng-th.component';
import {NgTdModule} from './ng-td/ng-td.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {CardModule} from '../partials/general/card/card.module';


@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        RouterModule.forChild([]),
        PaginateModule,
        NgTdModule,
        NgxSpinnerModule,
        PerfectScrollbarModule,
        CardModule
    ],
    declarations: [
        NgListComponent,
        NgThComponent
    ],
    exports: [
        NgListComponent,
        NgThComponent
    ]
})
export class TableModule { }
