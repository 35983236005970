import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {NgModalComponent} from '../ng-modal.component';
import {PasswordConfirmLang} from './password-confirm.lang';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DataStorageService} from '../../../_services/data-storage.service';
import {BaseFormService} from '../../../_services/base-form-service';
import {ModalService} from '../../../_services/modal.service';
import {ToolsService} from '../../../_services/tools.service';
import {PasswordConfirmService} from '../../../_services/password-confirm.service';
import {ApisRoutes} from '../../../_routes/apis-routes';
import {ProfileService} from '../../../_services/profile.service';

@Component({
    selector: 'app-password-confirm',
    templateUrl: './password-confirm.component.html',
    styleUrls: ['./password-confirm.component.scss', '../ng-modal.component.scss']
})

export class PasswordConfirmComponent implements OnInit, AfterViewChecked {

    public lang = new PasswordConfirmLang(this.dataStorage.envLang);

    public group = new FormGroup({});

    public spinnerName = 'password-confirm';

    public submitted = false;

    public apis = new ApisRoutes();

    constructor(public modal: NgbActiveModal,
                public dataStorage: DataStorageService,
                public base: BaseFormService,
                public tool: ToolsService,
                public confirmService: PasswordConfirmService,
                public cdRef: ChangeDetectorRef,
                public profileS: ProfileService,
    ) {}

    ngOnInit() {}

    doSend() {
        this.base.spinner.show(this.spinnerName);

        this.submitted = true;

        // stop here if form is invalid
        if (this.group.invalid) {
            this.base.spinner.hide(this.spinnerName);
            return;
        }

        this.base.spinner.show(this.spinnerName);
        this.base.post(this.apis.routes.auth.password.confirm,
            {...this.tool.structureParameters(this.group.value)},
            { msg: true, spinnerName: this.spinnerName}).subscribe(resp => {
                this.confirmService.passwordConfirmed();
                this.modal.close(true);
        });
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    doEnter(e) {
        e.preventDefault();
    }
}

