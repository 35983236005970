<!-- fixed-top-->
<nav
        class="top-header header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow navbar-static-top navbar-light navbar-brand-center"
        id="top-header" [ngClass]="selectedHeaderNavBarClass">
  <div class="navbar-wrapper">
    <div id="navbar-header" class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item mobile-menu d-md-none mr-auto" ><a
                class="nav-link nav-menu-main menu-toggle hidden-xs11" (click)="toggleNavigation($event)"><i
                class="feather ft-menu font-large-1" ></i></a></li>
        <li class="nav-item"><a [routerLink]="['/dashboard']" class="navbar-brand"><img class="brand-logo" alt="modern admin logo"
                                                                                        src="../../../../assets/images/logo/logo.png">
          <h3 class="brand-text">{{_themeSettingsConfig.brand.brand_name}}</h3>
        </a></li>

        <li class="nav-item d-md-none"><a class="nav-link open-navbar-container" data-toggle="collapse"
                                          data-target="#navbar-mobile" (click)="toggleNavbar($event)"><i class="la la-ellipsis-v"></i></a></li>
      </ul>
    </div>
    <div class="navbar-container content">
      <div class="collapse navbar-collapse show" id="navbar-mobile">
        <ul class="nav navbar-nav mr-auto float-left">
          <li class="nav-item d-none d-md-block"><a [routerLink]="" class="nav-link nav-menu-main menu-toggle hidden-xs"
                                                    (click)="toggleFixMenu($event)" ><i class="feather ft-menu"></i></a></li>
          <li class="nav-item d-none d-md-block"><a [routerLink]="" class="nav-link nav-link-expand"
                                                    (click)="toggleFullScreen()" *ngIf ="maximize === 'on'"><i class="ficon feather ft-maximize"></i></a></li>
          <li class="nav-item nav-search"><a [routerLink]="" class="nav-link nav-link-search" (click)="clickSearch()" *ngIf ="search === 'on'"><i
                  class="ficon feather ft-search"></i></a>
            <div class="search-input" [ngClass]="{'open': isHeaderSearchOpen}">
              <input class="input" type="text" placeholder="Explore Modern...">
            </div>
          </li>
        </ul>
        <ul class="nav navbar-nav float-right" >
          <li class="dropdown-language nav-item" ngbDropdown *ngIf ="internationalization === 'on'">
            <a [routerLink]="" class="dropdown-toggle nav-link" ngbDropdownToggle id="dropdown-flag">
              <i class="flag-icon flag-icon-gb"></i><span class="selected-language"></span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownLangMenu">
              <a [routerLink]="" class="dropdown-item">
                <i class="flag-icon flag-icon-es"></i> {{ lang.spanish }}
              </a>
              <a [routerLink]="" class="dropdown-item">
                <i class="flag-icon flag-icon-gb"></i> {{ lang.english }}
              </a>
            </div>
          </li>

          <li class="dropdown-user nav-item" ngbDropdown>
            <a class="nav-link dropdown-user-link" ngbDropdownToggle>
              <span *ngIf="currentUser?.name"
                    class="mr-1 user-name text-bold-700">{{currentUser?.name}} {{currentUser?.last_name}}</span>
              <span *ngIf="!currentUser?.name" class="mr-1 user-name text-bold-700">{{currentUser?.name}} {{currentUser?.last_name}}</span>
              <span class="avatar avatar-online">
<!--                <img *ngIf="currentUser?.photoURL" src="{{currentUser?.photoURL}}" alt="avatar">-->
                <img  src="../../../../assets/images/avatar.jpg" alt="avatar">
              </span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">
              <a class="dropdown-item" [routerLink]="['/edit-profile']"><i class="feather ft-user"></i>{{lang.edit}}</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" [routerLink]="" (click)="logout()"><i class="feather ft-power"></i>{{lang.logout}}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
