import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalContentInterface, NgModalLang} from './ng-modal.lang';
import {DataStorageService} from '../../_services/data-storage.service';
import {BaseFormService} from '../../_services/base-form-service';
import {ModalService} from '../../_services/modal.service';
import {ToolsService} from '../../_services/tools.service';
import {ProfileService} from "../../_services/profile.service";
import {FormGroup} from "@angular/forms";
import {ApisRoutes} from '../../_routes/apis-routes';

@Component({
    selector: 'ng-modal',
    templateUrl: './ng-modal.component.html',
    styleUrls: ['./ng-modal.component.scss']
})

export class NgModalComponent implements OnInit, AfterViewChecked {

    public apis = new ApisRoutes();

    public data: ModalContentInterface = new NgModalLang(this.dataStorage.envLang);

    public ink = 'danger';

    public group = new FormGroup({});

    public lang;

    public submitted = false;

    constructor(public modal: NgbActiveModal,
                public dataStorage: DataStorageService,
                public base: BaseFormService,
                public modalS: ModalService,
                public tool: ToolsService,
                public cdRef: ChangeDetectorRef,
                public profileS: ProfileService,
    ) { }

    ngOnInit() {
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    doEnter(e) {
        e.preventDefault();
    }
}

