export class DashboardLang implements DashboardLangInterface {

    title = 'Dashboard';
    load = 'Loading..';
    edit = 'Edit Profile';
    logout = 'Log out';
    spanish = 'Espanish';
    english = 'English';
    selectCountry = 'Select a country';

    public constructor(lang) {
        return this[lang] || this;
    }

    es: DashboardLangInterface = {
        title: 'Panel Administrativo',
        load: 'Cargando..',
        edit : 'Editar Perfil',
        logout : 'Salir del sistema',
        spanish : 'Español',
        english: 'Inglés',
        selectCountry: 'Seleccionar un país',
    };

    pt: DashboardLangInterface = {
        title: 'Painel',
        load: 'Carregando..',
        edit: 'Editar Perfil',
        logout: 'Sair',
        spanish: 'Espanhol',
        english: 'português',
        selectCountry: 'Selecione um pais',
    };
}


export class DashboardLangInterface {
    title: string;
    load: string;
    edit: string;
    logout: string;
    spanish: string;
    english: string;
    selectCountry: string;
}




