import {DataStorageService} from './data-storage.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {LangEnum} from '../_feature/lang.enum';

@Injectable({
    providedIn: 'root'
})
export class LangService implements OnInit, OnDestroy {
    constructor(private storage: DataStorageService) {}

    public lang = new ServiceLang(this.storage.envLang);

    private langSubject = new Subject<LangInterface>();

    private langSubscribe: Subscription;

    get availableLangs(): Array<LangInterface> {
        return [
            {
                label: this.lang.spanish,
                language: LangEnum.es,
                flag: 'es'
            },
            {
                label: this.lang.portuguese,
                language: LangEnum.pt,
                flag: 'br'
            },
            {
                label: this.lang.english,
                language: LangEnum.en,
                flag: 'us'
            }
        ];
    }

    public setLanguage(langInterface: LangInterface) {
        this.storage.setLang(langInterface.language);
        this.langSubject.next(langInterface);
        this.lang = new ServiceLang(langInterface.language);
    }

    public getObservable(): Observable<LangInterface> {
        return this.langSubject.asObservable();
    }

    ngOnInit(): void {
        this.langSubscribe = this.langSubject.asObservable().subscribe((lang) => {
            this.lang = new ServiceLang(lang.language);
        });
    }

    ngOnDestroy(): void {
        this.langSubject.unsubscribe();
        this.langSubscribe.unsubscribe();
    }
}

export class ServiceLang implements LangServiceInterface {

    spanish = 'Spanish';
    english = 'English';
    portuguese = 'Portuguese';

    public constructor(private lang: string) {
        return this[lang] || this;
    }

    es = {
        spanish : 'Español',
        english: 'Inglés',
        portuguese: 'Portugues'
    };

    pt = {
        spanish: 'Espanhol',
        english: 'Inglês',
        portuguese: 'Português',
    };
}

export interface LangServiceInterface {
    spanish: string;
    english: string;
    portuguese: string;
}

export interface LangInterface {
    label: string;
    language: LangEnum;
    flag: string;
}

