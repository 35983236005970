import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SortTypeEnum} from '../../../_feature/api-response';
import {SortInterface} from '../ng-list.component';

@Component({
    selector: 'ng-th',
    templateUrl: './ng-th.component.html',
    styleUrls: ['./ng-th.component.scss']
})

export class NgThComponent implements OnInit, OnChanges {

    public sortType = '';

    public _sortKey;

    @Input() value: string;

    @Input() sorteable: boolean;

    @Input() sortKey = '';

    @Input() currentSort = '';

    @Output() sort = new EventEmitter<SortInterface>();

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const changeName in changes) {
            const change = changes[changeName];
            const previousValue = change.previousValue;
            const currentValue  = change.currentValue;

            if (changeName === 'sortKey') {
                if (previousValue !== undefined && currentValue !== null) {
                    console.log('sortKey', this.sortKey);
                    this._sortKey = this.sortKey;
                }
            }

            if (changeName === 'currentSort') {
                if (previousValue !== undefined && currentValue !== null) {

                    if (this.currentSort !== this.sortKey) {
                        this.sortType = '';
                        this._sortKey = null;
                    }
                }
            }
        }
    }

    get iconType(): string {

        if (!this._sortKey) {
            return '';
        }

        let sty =  this.sortType === SortTypeEnum.asc ? 'fas fa-chevron-up' : '';
        sty = !this.sortType ||
        this.sortType === SortTypeEnum.desc ? 'fas fa-chevron-down' : sty;

        return sty;
    }

    toSort() {
        this.sortConfig(this.sortType);
        this.sort.emit({
            sort : this._sortKey,
            sortType : this.sortType
        });
    }


    sortConfig(type) {
        this._sortKey = this.sortKey;
        switch (type) {
            case 'desc':
                this.sortType = '';
                this._sortKey = null;
                break;
            case 'asc':
                this.sortType = 'desc';
                break;
            default:
                this.sortType = 'asc';
                break;
        }
    }

}
