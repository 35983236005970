import {InjectionToken} from '@angular/core';

export interface CurrencyMaskConfig {
   align: string;
   allowNegative: boolean;
   allowZero: boolean;
   decimal: string;
   precision: number;
   prefix: string;
   suffix: string;
   thousands: string;
   nullable: boolean;
}

export let CURRENCY_MASK_CONFIG = new InjectionToken<CurrencyMaskConfig>('currency.mask.config');

import {
   AfterViewInit,
   Directive,
   DoCheck,
   ElementRef,
   forwardRef,
   HostListener,
   Inject,
   KeyValueDiffer,
   KeyValueDiffers,
   Input,
   OnInit,
   Optional
} from '@angular/core';

import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export const CURRENCY_MASK_DIRECTIVE_VALUE_ACCESSOR: any = {
   provide: NG_VALUE_ACCESSOR,
   useExisting: forwardRef(() => CurrencyMaskDirective),
   multi: true,
};

@Directive({
   selector: '[currencyMask]',
   providers: [CURRENCY_MASK_DIRECTIVE_VALUE_ACCESSOR]
})
export class CurrencyMaskDirective implements AfterViewInit, ControlValueAccessor, DoCheck, OnInit {

   @Input() options: Partial<CurrencyMaskConfig> = {};

   public inputHandler: InputHandler;
   public keyValueDiffer: KeyValueDiffer<any, any>;

   public optionsTemplate = {
      align: 'right',
      allowNegative: true,
      allowZero: true,
      decimal: '.',
      precision: 2,
      prefix: '$ ',
      suffix: '',
      thousands: ',',
      nullable: false
   };

   constructor(@Optional() @Inject(CURRENCY_MASK_CONFIG) private currencyMaskConfig: CurrencyMaskConfig,
               private elementRef: ElementRef,
               private keyValueDiffers: KeyValueDiffers) {
      if (currencyMaskConfig) {
         this.optionsTemplate = currencyMaskConfig;
      }

      this.keyValueDiffer = keyValueDiffers.find({}).create();
   }

   ngAfterViewInit() {
      this.elementRef.nativeElement.style.textAlign = this.options ? this.options.align : this.optionsTemplate.align;
   }

   ngDoCheck() {
      if (this.keyValueDiffer.diff(this.options)) {
         this.elementRef.nativeElement.style.textAlign = this.options.align ? this.options.align : this.optionsTemplate.align;
         this.inputHandler.updateOptions((<any>Object).assign({}, this.optionsTemplate, this.options));
      }
   }

   ngOnInit() {
      this.inputHandler = new InputHandler(this.elementRef.nativeElement, (<any>Object).assign({}, this.optionsTemplate, this.options));
   }

   @HostListener('blur', ['$event'])
   handleBlur(event: any) {
      this.inputHandler.getOnModelTouched().apply(event);
   }

   @HostListener('cut', ['$event'])
   handleCut(event: any) {
      this.inputHandler.handleCut(event);
   }

   @HostListener('input', ['$event'])
   handleInput(event: any) {
      this.inputHandler.handleInput(event);
   }

   @HostListener('keydown', ['$event'])
   handleKeydown(event: any) {
      this.inputHandler.handleKeydown(event);
   }

   @HostListener('keypress', ['$event'])
   handleKeypress(event: any) {
      this.inputHandler.handleKeypress(event);
   }

   @HostListener('paste', ['$event'])
   handlePaste(event: any) {
      this.inputHandler.handlePaste(event);
   }

   registerOnChange(callbackFunction: Function): void {
      this.inputHandler.setOnModelChange(callbackFunction);
   }

   registerOnTouched(callbackFunction: Function): void {
      this.inputHandler.setOnModelTouched(callbackFunction);
   }

   setDisabledState(value: boolean): void {
      this.elementRef.nativeElement.disabled = value;
   }

   writeValue(value: number): void {
      this.inputHandler.setValue(value);
   }
}

export class InputHandler {

   private inputService: InputService;
   private onModelChange: Function;
   private onModelTouched: Function;

   constructor(htmlInputElement: HTMLInputElement, options: any) {
      this.inputService = new InputService(htmlInputElement, options);
   }

   handleCut(event: any): void {
      setTimeout(() => {
         this.inputService.updateFieldValue();
         this.setValue(this.inputService.value);
         this.onModelChange(this.inputService.value);
      }, 0);
   }

   handleInput(event: any): void {
      const keyCode = this.inputService.rawValue.charCodeAt(this.inputService.rawValue.length - 1);
      const rawValueLength = this.inputService.rawValue.length;
      const rawValueSelectionEnd = this.inputService.inputSelection.selectionEnd;
      const storedRawValueLength = this.inputService.storedRawValue.length;
      this.inputService.rawValue = this.inputService.storedRawValue;

      if (rawValueLength !== rawValueSelectionEnd || Math.abs(rawValueLength - storedRawValueLength) !== 1) {
         this.setCursorPosition(event);
         return;
      }

      if (rawValueLength < storedRawValueLength) {
         this.inputService.removeNumber(8);
      }

      if (rawValueLength > storedRawValueLength) {
         switch (keyCode) {
            case 43:
               this.inputService.changeToPositive();
               break;
            case 45:
               this.inputService.changeToNegative();
               break;
            default:
               if (!this.inputService.canInputMoreNumbers) {
                  return;
               }

               this.inputService.addNumber(keyCode);
         }
      }

      this.setCursorPosition(event);
      this.onModelChange(this.inputService.value);
   }

   handleKeydown(event: any): void {
      const keyCode = event.which || event.charCode || event.keyCode;

      if (keyCode === 8 || keyCode === 46 || keyCode === 63272) {
         event.preventDefault();
         const selectionRangeLength = Math.abs(
            this.inputService.inputSelection.selectionEnd - this.inputService.inputSelection.selectionStart
         );

         if (selectionRangeLength === 0) {
            this.inputService.removeNumber(keyCode);
            this.onModelChange(this.inputService.value);
         }

         if (selectionRangeLength >= (this.inputService.rawValue.length - this.inputService.prefixLength())) {
            this.clearValue();
         }
      }
   }

   clearValue() {
      this.setValue(this.inputService.isNullable() ? null : 0);
      this.onModelChange(this.inputService.value);
   }

   handleKeypress(event: any): void {
      const keyCode = event.which || event.charCode || event.keyCode;

      if (keyCode === 97 && event.ctrlKey) {
         return;
      }

      switch (keyCode) {
         case undefined:
         case 9:
         case 13:
         case 37:
         case 39:
            return;
         case 43:
            this.inputService.changeToPositive();
            break;
         case 45:
            this.inputService.changeToNegative();
            break;
         default:
            if (this.inputService.canInputMoreNumbers) {
               const selectionRangeLength = Math.abs(
                  this.inputService.inputSelection.selectionEnd - this.inputService.inputSelection.selectionStart
               );

               if (selectionRangeLength === this.inputService.rawValue.length) {
                  this.setValue(0);
               }

               this.inputService.addNumber(keyCode);
            }
      }

      event.preventDefault();
      this.onModelChange(this.inputService.value);
   }

   handlePaste(event: any): void {
      setTimeout(() => {
         this.inputService.updateFieldValue();
         this.setValue(this.inputService.value);
         this.onModelChange(this.inputService.value);
      }, 1);
   }

   updateOptions(options: any): void {
      this.inputService.updateOptions(options);
   }

   getOnModelChange(): Function {
      return this.onModelChange;
   }

   setOnModelChange(callbackFunction: Function): void {
      this.onModelChange = callbackFunction;
   }

   getOnModelTouched(): Function {
      return this.onModelTouched;
   }

   setOnModelTouched(callbackFunction: Function) {
      this.onModelTouched = callbackFunction;
   }

   setValue(value: number): void {
      this.inputService.value = value;
   }

   private setCursorPosition(event: any): void {
      setTimeout(function () {
         event.target.setSelectionRange(event.target.value.length, event.target.value.length);
      }, 0);
   }
}

class InputService {
   PER_AR_NUMBER: Map<string, string> = new Map<string, string>();

   inputManager: InputManager;

   initialize() {
      this.PER_AR_NUMBER.set('\u06F0', '0');
      this.PER_AR_NUMBER.set('\u06F1', '1');
      this.PER_AR_NUMBER.set('\u06F2', '2');
      this.PER_AR_NUMBER.set('\u06F3', '3');
      this.PER_AR_NUMBER.set('\u06F4', '4');
      this.PER_AR_NUMBER.set('\u06F5', '5');
      this.PER_AR_NUMBER.set('\u06F6', '6');
      this.PER_AR_NUMBER.set('\u06F7', '7');
      this.PER_AR_NUMBER.set('\u06F8', '8');
      this.PER_AR_NUMBER.set('\u06F9', '9');

      this.PER_AR_NUMBER.set('\u0660', '0');
      this.PER_AR_NUMBER.set('\u0661', '1');
      this.PER_AR_NUMBER.set('\u0662', '2');
      this.PER_AR_NUMBER.set('\u0663', '3');
      this.PER_AR_NUMBER.set('\u0664', '4');
      this.PER_AR_NUMBER.set('\u0665', '5');
      this.PER_AR_NUMBER.set('\u0666', '6');
      this.PER_AR_NUMBER.set('\u0667', '7');
      this.PER_AR_NUMBER.set('\u0668', '8');
      this.PER_AR_NUMBER.set('\u0669', '9');
   }

   constructor(private htmlInputElement: any, private options: CurrencyMaskConfig) {
      this.inputManager = new InputManager(htmlInputElement);
      this.initialize();
   }

   addNumber(keyCode: number): void {
      if (!this.rawValue) {
         this.rawValue = this.applyMask(false, '0');
      }

      const keyChar = String.fromCharCode(keyCode);
      const selectionStart = this.inputSelection.selectionStart;
      const selectionEnd = this.inputSelection.selectionEnd;
      this.rawValue = this.rawValue.substring(0, selectionStart) + keyChar + this.rawValue.substring(selectionEnd, this.rawValue.length);
      this.updateFieldValue(selectionStart + 1);
   }

   applyMask(isNumber: boolean, rawValue: string): string {
      const { allowNegative, decimal, precision, prefix, suffix, thousands, nullable } = this.options;
      rawValue = isNumber ? Number(rawValue).toFixed(precision) : rawValue;
      const onlyNumbers = rawValue.replace(/[^0-9\u0660-\u0669\u06F0-\u06F9]/g, '');

      if (!onlyNumbers) {
         return '';
      }

      let integerPart = onlyNumbers.slice(0, onlyNumbers.length - precision)
         .replace(/^\u0660*/g, '')
         .replace(/^\u06F0*/g, '')
         .replace(/^0*/g, '')
         .replace(/\B(?=([0-9\u0660-\u0669\u06F0-\u06F9]{3})+(?![0-9\u0660-\u0669\u06F0-\u06F9]))/g, thousands);

      if (thousands && integerPart.startsWith(thousands)) {
         integerPart = integerPart.substring(1);
      }


      if (integerPart === '') {
         integerPart = '0';
      }

      let newRawValue = integerPart;
      const decimalPart = onlyNumbers.slice(onlyNumbers.length - precision);

      if (precision > 0) {
         newRawValue += decimal + decimalPart;
      }

      const isZero = parseInt(integerPart, 0) === 0 && (parseInt(decimalPart, 0) === 0 || decimalPart === '');
      const operator = (rawValue.indexOf('-') > -1 && allowNegative && !isZero) ? '-' : '';
      return operator + prefix + newRawValue + suffix;
   }

   clearMask(rawValue: string): number {
      if (this.isNullable() && rawValue === '') {
         return null;
      }

      let value = (rawValue || '0').replace(this.options.prefix, '').replace(this.options.suffix, '');

      if (this.options.thousands) {
         value = value.replace(new RegExp('\\' + this.options.thousands, 'g'), '');
      }

      if (this.options.decimal) {
         value = value.replace(this.options.decimal, '.');
      }

      this.PER_AR_NUMBER.forEach((val: string, key: string) => {
         const re = new RegExp(key, 'g');
         value = value.replace(re, val);
      });
      return parseFloat(value);
   }

   changeToNegative(): void {
      if (this.options.allowNegative && this.rawValue !== '' && this.rawValue.charAt(0) !== '-' && this.value !== 0) {
         this.rawValue = '-' + this.rawValue;
      }
   }

   changeToPositive(): void {
      this.rawValue = this.rawValue.replace('-', '');
   }

   removeNumber(keyCode: number): void {
      if (this.isNullable() && this.value === 0) {
         this.rawValue = null;
         return;
      }

      let selectionEnd = this.inputSelection.selectionEnd;
      let selectionStart = this.inputSelection.selectionStart;

      if (selectionStart > this.rawValue.length - this.options.suffix.length) {
         selectionEnd = this.rawValue.length - this.options.suffix.length;
         selectionStart = this.rawValue.length - this.options.suffix.length;
      }

      let move = this.rawValue.substr(selectionStart - 1, 1).match(/\d/) ? 0 : -1;
      if ((
            keyCode === 8 &&
            selectionStart - 1 === 0 &&
            !(this.rawValue.substr(selectionStart, 1).match(/\d/))
         ) ||
         (
            (keyCode === 46 || keyCode === 63272) &&
            selectionStart === 0 &&
            !(this.rawValue.substr(selectionStart + 1, 1).match(/\d/))
         )
      ) {
         move = 1;
      }
      selectionEnd = keyCode === 46 || keyCode === 63272 ? selectionEnd + 1 : selectionEnd;
      selectionStart = keyCode === 8 ? selectionStart - 1 : selectionStart;
      this.rawValue = this.rawValue.substring(0, selectionStart) + this.rawValue.substring(selectionEnd, this.rawValue.length);
      this.updateFieldValue(selectionStart + move);
   }

   updateFieldValue(selectionStart?: number): void {
      const newRawValue = this.applyMask(false, this.rawValue || '');
      selectionStart = selectionStart === undefined ? this.rawValue.length : selectionStart;
      this.inputManager.updateValueAndCursor(newRawValue, this.rawValue.length, selectionStart);
   }

   updateOptions(options: any): void {
      const value: number = this.value;
      this.options = options;
      this.value = value;
   }

   prefixLength(): any {
      return this.options.prefix.length;
   }

   isNullable() {
      return this.options.nullable;
   }

   get canInputMoreNumbers(): boolean {
      return this.inputManager.canInputMoreNumbers;
   }

   get inputSelection(): any {
      return this.inputManager.inputSelection;
   }

   get rawValue(): string {
      return this.inputManager.rawValue;
   }

   set rawValue(value: string) {
      this.inputManager.rawValue = value;
   }

   get storedRawValue(): string {
      return this.inputManager.storedRawValue;
   }

   get value(): number {
      return this.clearMask(this.rawValue);
   }

   set value(value: number) {
      this.rawValue = this.applyMask(true, '' + value);
   }
}

export class InputManager {

   private _storedRawValue: string;

   constructor(private htmlInputElement: any) {
   }

   setCursorAt(position: number): void {
      if (this.htmlInputElement.setSelectionRange) {
         this.htmlInputElement.focus();
         this.htmlInputElement.setSelectionRange(position, position);
      } else if (this.htmlInputElement.createTextRange) {
         const textRange = this.htmlInputElement.createTextRange();
         textRange.collapse(true);
         textRange.moveEnd('character', position);
         textRange.moveStart('character', position);
         textRange.select();
      }
   }

   updateValueAndCursor(newRawValue: string, oldLength: number, selectionStart: number): void {
      this.rawValue = newRawValue;
      const newLength = newRawValue.length;
      selectionStart = selectionStart - (oldLength - newLength);
      this.setCursorAt(selectionStart);
   }

   get canInputMoreNumbers(): boolean {
      const haventReachedMaxLength = !(this.rawValue.length >= this.htmlInputElement.maxLength && this.htmlInputElement.maxLength >= 0);
      const selectionStart = this.inputSelection.selectionStart;
      const selectionEnd = this.inputSelection.selectionEnd;
      const haveNumberSelected = !!(selectionStart !== selectionEnd &&
         this.htmlInputElement.value.substring(selectionStart, selectionEnd).match(/[^0-9\u0660-\u0669\u06F0-\u06F9]/));
      const startWithZero = (this.htmlInputElement.value.substring(0, 1) === '0');
      return haventReachedMaxLength || haveNumberSelected || startWithZero;
   }

   get inputSelection(): any {
      let selectionStart = 0;
      let selectionEnd = 0;

      if (typeof this.htmlInputElement.selectionStart === 'number' && typeof this.htmlInputElement.selectionEnd === 'number') {
         selectionStart = this.htmlInputElement.selectionStart;
         selectionEnd = this.htmlInputElement.selectionEnd;
      } else {
         const range = (<any>document).selection.createRange();

         if (range && range.parentElement() === this.htmlInputElement) {
            const lenght = this.htmlInputElement.value.length;
            const normalizedValue = this.htmlInputElement.value.replace(/\r\n/g, '\n');
            const startRange = this.htmlInputElement.createTextRange();
            startRange.moveToBookmark(range.getBookmark());
            const endRange = this.htmlInputElement.createTextRange();
            endRange.collapse(false);

            if (startRange.compareEndPoints('StartToEnd', endRange) > -1) {
               selectionStart = selectionEnd = lenght;
            } else {
               selectionStart = -startRange.moveStart('character', -lenght);
               selectionStart += normalizedValue.slice(0, selectionStart).split('\n').length - 1;

               if (startRange.compareEndPoints('EndToEnd', endRange) > -1) {
                  selectionEnd = lenght;
               } else {
                  selectionEnd = -startRange.moveEnd('character', -lenght);
                  selectionEnd += normalizedValue.slice(0, selectionEnd).split('\n').length - 1;
               }
            }
         }
      }

      return {
         selectionStart: selectionStart,
         selectionEnd: selectionEnd
      };
   }

   get rawValue(): string {
      return this.htmlInputElement && this.htmlInputElement.value;
   }

   set rawValue(value: string) {
      this._storedRawValue = value;

      if (this.htmlInputElement) {
         this.htmlInputElement.value = value;
      }
   }

   get storedRawValue(): string {
      return this._storedRawValue;
   }
}
