import { Injectable } from '@angular/core';

import {
    FormControl,
    Validators,
} from '@angular/forms';
import {FieldLang} from '../_fields/field.lang';
import {DataStorageService} from './data-storage.service';

@Injectable()

export class FormBuilderService {

    private lang = new FieldLang(this.storage.envLang);

    private langErr = this.lang.errors;

    constructor(private storage: DataStorageService) { }

    validatorParams(arr: any[]): FormControl {

        const validField = new FormControl;

        const valits: any[] = [];

        for (const value of arr ) {


            if (value[0] === 'required') {

                valits.push(Validators.required);
            } else {

                valits.push( Validators[value[0]](value[1]) );
            }
        }

        validField.setValidators(valits);

        return validField;
    }

    getError(errors: Object): any[] {

        const errs: any[] = [];

        if (errors) {

            for (const err in errors) {

                const requires: any[] = [];

                if (errors[err]) {

                    for (const req in errors[err]) {

                        requires.push(errors[err][req]);
                    }
                }

                errs.push({'error' : err, 'required' : requires });
            }
        }

        return errs;
    }

    setErrorMessage(field: string, errors: Object): any[] {

        const errArray: any[] = [];

        let message: string;

        const getErros = this.getError(errors);

        for (const key in getErros) {

            let fix = getErros[key].required[0] - getErros[key].required[1];

            let lengthErr = this.langErr[getErros[key].error].replace('::required', getErros[key].required[0]);

            switch (getErros[key].error) {

                case 'maxlength':
                case 'minlength':

                    message = `${field} ${ lengthErr }`;

                    break;
                case 'max':
                case 'min':

                    fix = fix < 0 ?  fix * -1 : fix;

                    lengthErr = lengthErr.replace('::fix', fix);

                    lengthErr = lengthErr.replace('::'
                        + getErros[key].error, getErros[key].required[0]);

                    message = `${field} ${ lengthErr }`;

                    break;

                case 'pattern':
                case 'required':
                case 'divisible':

                    lengthErr = lengthErr.replace('::' + getErros[key].error, getErros[key].required[0]);

                    message = `${field} ${ lengthErr }`;

                    break;
            }

            errArray.push(message);
        }

        return errArray;

    }
}
