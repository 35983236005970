import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {PaginateMetaInterface} from '../../_feature/api-response';
import {BaseFormService} from '../../_services/base-form-service';
import {DataStorageService, ParseStorageEnum} from '../../_services/data-storage.service';
import {ToolsService} from '../../_services/tools.service';
import {PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective} from 'ngx-perfect-scrollbar';
import {ModalOptionInterface, ModalService} from '../../_services/modal.service';
import {NgPaginateComponent} from '../paginate/ng-paginate.component';
import {ActionsInterface} from './ng-td/ng-actions/ng-actions.component';
import {NgModalComponent} from '../modal/ng-modal.component';
import {SearchService} from '../../_services/search.service';
import {LangService} from '../../_services/lang.service';
import {CredentialService} from '../../_services/credential.service';

@Component({
    selector: 'ng-list',
    templateUrl: './ng-list.component.html',
    styleUrls: ['./ng-list.component.scss']
})

export class NgListComponent implements OnInit, OnDestroy {

    public apisList: ApisListInterface;

    // list

    public items = []; // items list

    public params = {};

    public metaPaginate: PaginateMetaInterface;

    public dataAlert = {};

    public spinnerName = 'list';

    // actions
    public actionList: ActionsInterface[] = [
    ];

    public configCrud: object = {};

    public configModal: ModalOptionInterface = {
        windowClass: 'push-modal',
        duration : 500
    };

    public filterActive = '';

    public searchServiceRef: Subscription = null;

    @ViewChild(NgPaginateComponent, {static: false}) ngPaginate: NgPaginateComponent;

    public configCountry: Subscription;

    public config: PerfectScrollbarConfigInterface = { suppressScrollY: true };
    @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;

    constructor(public base: BaseFormService,
                public storage: DataStorageService,
                public activeRoute: ActivatedRoute,
                public modal: ModalService,
                public tool: ToolsService,
                public langService: LangService,
                public credential: CredentialService,
                public search: SearchService) {}

    ngOnInit() {

        this.searchServiceRef = this.search.searchEvent.subscribe((value) => {
            this.changeSearch(value);
        });
    }

    load(event?: number) {
        this.base.spinner.show(this.spinnerName);
        this.base.get(this.apisList.list, {urlParams: {page: event, ...this.params}, spinnerName: this.spinnerName}).subscribe(resp => {
            this.items = resp.body;
            this.metaPaginate = resp.meta;
        });
    }

    // sort list
    doSort(event: SortInterface) {
        if (event.sort) {
            if (this.params['sort'] !== event.sort || this.params['sortType'] !== event.sortType) {
                this.metaPaginate = null;
            }
            this.params = event;
            return;
        }

        this.params = {};
        this.metaPaginate = null;
    }

    changeSearch(value: string) {
        if (value) {
            if (this.params['search'] !== value) {
                this.metaPaginate = null;
            }
            this.params['search'] = value;

            this.filterActive = value;
            return;
        }

        this.params = {};
        this.metaPaginate = null;
    }

    resetSearch() {
        this.filterActive = '';
        this.search.doReset();
    }

    ngOnDestroy() {
    }

    methodAction(event, id) {
        switch (event) {
            case 'edit':
                // open modal update
                this.doUpdate(id);
                break;
            case 'del':
                // open modal alert
                this.doDelete(id);
                break;
        }
    }

    doCreate() {
        // create-modal
    }

    doUpdate(id) {
        // update-modal
    }

    doDelete(id) {
        // delete-modal
        const modalRef = this.modal.open(NgModalComponent);
        if (this.dataAlert) {
            modalRef.componentInstance.data = this.dataAlert;
        }
        modalRef.result.then((result) => {
            if (result) {
                this.base.spinner.show();

                this.base.del(this.apisList.del, id, {msg: true}).subscribe(() => {
                    this.ngPaginate.bestReload();
                });
            }
        }).catch((result) => {
            console.log('Dismissed', result);
        });
    }

}

export class ApisListInterface {
    list: string;
    del?: string;
}

export interface SortInterface {
    sort?: string;
    sortType: string;
}
