import {AuthRoutes} from './auth-routes';
import {AdminRoutes} from './admin-routes';
import {CommonRoutes} from './common-routes';
import {PublicsRoutes} from './publics-routes';


export class ApisRoutes {
    public routes = {
        ...new AuthRoutes(),
        ...new AdminRoutes(),
        ...new CommonRoutes(),
        ...new PublicsRoutes()
    };
}
