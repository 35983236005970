export class PasswordConfirmLang implements PasswordConfirmLangInterface {

    title = 'Password confirmation required';
    password = 'Password';
    buttons = {
        confirm: 'Confirm',
        cancel: 'Cancel'
    };

    public constructor(lang) {
        return this[lang] || this;
    }

    es: PasswordConfirmLangInterface = {
        title: 'Confirmacion de contraseña necesaria',
        password: 'Contraseña',
        buttons: {
            confirm: 'Confirmar',
            cancel: 'Cancelar'
        }
    };

    pt: PasswordConfirmLangInterface = {
        title: 'Confirmação de senha necessária',
        password: 'Senha',
        buttons: {
            confirm: 'confirme',
            cancel: 'Cancelar'
        },
    };
}

export interface PasswordConfirmLangInterface {
    title: string;
    password: string;
    buttons: {
        confirm: string,
        cancel: string
    };
}
