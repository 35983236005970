<nav class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-shadow"
     [ngClass]="selectedHeaderNavBarClass">
    <div class="navbar-wrapper">
        <div id="navbar-header" class="navbar-header" [ngClass]="selectedNavBarHeaderClass"
             (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
            <ul class="nav navbar-nav flex-row">
                <!-- Remove position relative in responsive -->
                <li class="nav-item mobile-menu d-lg-none mr-auto">
                    <a class="nav-link nav-menu-main menu-toggle hidden-xs11" (click)="toggleNavigation($event)">
                    <i class="feather ft-menu font-large-1"></i>
                    </a>
                </li>
                <li class="nav-item mr-auto"><a [routerLink]="['/dashboard']" class="navbar-brand" routerLink="/dashboard">
                    <img class="brand-logo" alt="modern admin logo" [src]="_themeSettingsConfig.brand.logo.value">
                    <h3 class="brand-text">{{_themeSettingsConfig.brand.brand_name}}</h3>
                </a></li>
                <li class="nav-item d-none d-md-block nav-toggle">
                    <a [routerLink]="" class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"
                       (click)="toggleFixMenu($event)">
                        <i class="feather toggle-icon font-medium-3 white"
                           [ngClass]="{'ft-toggle-left': _themeSettingsConfig.menu == 'collapse',
                                       'ft-toggle-right': _themeSettingsConfig.menu == 'expand'}"></i>
                    </a>
                </li>
                <li class="nav-item d-lg-none">
                    <a class="nav-link open-navbar-container"
                       data-toggle="collapse" data-target="#navbar-mobile"
                       (click)="toggleNavbar($event)">

                        <i class="la la-ellipsis-v"></i>
                    </a>
                </li>
            </ul>
        </div>
        <!-- New-->
        <div class="navbar-container content" [hidden]="isMobile && !showNavbar">
            <div class="collapse navbar-collapse" id="navbar-mobile">
                <ul class="nav navbar-nav mr-auto float-left">
                    <li class="nav-item d-none d-md-block">
                        <a [routerLink]="" class="nav-link nav-link-expand" (click)="toggleFullScreen()" *ngIf="maximize === 'on'">
                            <i class="ficon feather ft-maximize"></i>
                        </a>
                    </li>
                    <li class="nav-item d-none d-md-block" ngbDropdown>
                        <a [routerLink]="" class="nav-link nav-link-expand" ngbDropdownToggle id="dropdown-country" *ngIf="SelectedCountry">
                            <i class="flag-icon flag-icon-{{currentCountry.iso.toLowerCase()}}"></i>
                            <span class="selected-language">
                                {{currentCountry.name}}
                            </span>
                        </a>
                        <a [routerLink]="" class="nav-link nav-link-expand" ngbDropdownToggle id="dropdown-select-country" *ngIf="!SelectedCountry">
                            {{ lang.selectCountry }} <i class="fas fa-chevron-down"></i>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownLangMenu">
                            <a class="dropdown-item" *ngFor="let country of countriesOptions" (click)="itemCountry(country)">
                                <i class="flag-icon flag-icon-{{country.iso.toLowerCase()}}"></i> {{ country.name }}
                            </a>
                        </div>
                    </li>
                    <ng-search></ng-search>

                </ul>
                <ul class="nav navbar-nav float-right">
                    <li class="dropdown-language nav-item" ngbDropdown *ngIf="internationalization === 'on'">
                        <a [routerLink]="" class="dropdown-toggle nav-link" ngbDropdownToggle id="dropdown-flag">
                            <i class="flag-icon flag-icon-{{currentLanguageFlag}}"></i><span class="selected-language"></span>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownLangMenu">
                            <a class="dropdown-item" *ngFor="let langOption of langService.availableLangs" (click)="itemAction(langOption)">
                                <i class="flag-icon flag-icon-{{langOption.flag}}"></i> {{ langOption.label }}
                            </a>
                        </div>
                    </li>
                    <li class="dropdown-user nav-item" ngbDropdown>
                        <a class="nav-link dropdown-user-link" ngbDropdownToggle>
                            <span class="mr-1 user-name text-bold-700">
                                  {{fullName ? fullName : dataUser?.profile?.name + ' ' + dataUser?.profile?.last_name}}
                            </span>
                            <span class="avatar avatar-online">
                               <img [src]="dataUser?.avatar ? dataUser?.avatar : 'assets/images/avatar.jpg'" alt="avatar">
                            </span>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">
                            <a class="dropdown-item" [routerLink]="['/edit-profile']">
                                <i class="feather ft-user"></i>{{lang.edit}}
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" [routerLink]="" (click)="logout()">
                                <i class="feather ft-power"></i>{{lang.logout}}
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- New-->
    </div>
</nav>
