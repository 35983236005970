export class ValidateSchemas {

    validatorType = {

        email : {

            'type': 'email',

            'validators': [

                ['pattern', /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/],

                ['maxLength', 45]
            ]
        },

        text : {

            'type': 'text',

            'validators': []
        },

        textarea : {

            'type': 'text',

            'validators': []
        },
        iso : {     // 12

            'type': 'text',

            'validators': [

                ['pattern', /^([a-zA-Z]{2})$/],

                ['maxLength', 2]
            ]
        },

        currencys : {     // 123

            'type': 'text',

            'validators': [

                ['pattern', /^([a-zA-Z]{3})$/],

                ['maxLength', 3]
            ]
        },

        symbol : {

            'type': 'text',

            'validators': [

                ['pattern', /^([a-zA-Z]{4,10})$/],

                ['maxLength', 10]
            ]
        },
        integer : {

            'type': 'number',

            'validators': []
        },

        tinymce : {

            'type': 'text',

            'validators': []
        },

        default : {

            'type': 'text',

            'validators': []
        },

        password  : {

            'type': 'password',

            'validators': [

                ['minLength', 8],

                ['maxLength', 30]
            ]
        },

        simplePassword  : {

            'type': 'password',

            'validators': []
        },

        ptyPassword  : {

            'type': 'password',

            'validators': [

                ['pattern', /^(?=.*\d)(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[~`¡!/¿?&*\\|()[\]{}_+=@#\$%-_<>'.,;:^]).{6,}$/],
            ]
        },
        timeZone  : {

            'type': 'text',

            'mask': '[+|-]99:99',

            'validators': [

                ['pattern', /^[+-]([01][0-9]|2[0-3]):([0-5][0-9])$/],
            ]
        },
        year  : {

            'type': 'text',

            'validators': [

                ['pattern', /^([0-9]{4})$/],

                ['maxLength', 4]
            ]
        },
        percentage  : {

            'type': 'text',

            'mask': '999',

            'validators': [

                ['pattern', /^[0-9]{3}$/],
            ]
        },
        hour  : {

            'type': 'text',

            'mask': '99:99',

            'validators': [

                ['pattern', /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/],
            ],
        },
        day  : {

            'type': 'text',

            'mask': '99-99',

            'validators': [

                ['pattern', /^(?:\d{4}-)?((?!(02-31)|(02-30))(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]))$/],
            ],
        },

        userPassword : {

            'type': 'password',

            'validators': [

                ['minLength', 8],

                ['maxLength', 50]
            ]
        },


        visiblePassword : {

            'type': 'text',

            'validators': [

                ['minLength', 8],

                ['maxLength', 30]
            ]
        },

        search : {
            'type': 'text',

            'validators': [

                ['minLength', 3],

                ['maxLength', 25]
            ]
        },

        name : {

            'type': 'text',

            'validators': [

                ['minLength', 3],

                ['maxLength', 20]
            ]
        },

        bracketName : {

            'type': 'text',

            'validators': [

                ['pattern', /^[0-9a-zA-Z\-()\s]{1,255}$/],

                ['minLength', 1],

                ['maxLength', 255]
            ]
        },

        directiveName : {

            'type': 'text',

            'validators': [

                ['pattern', /^[0-9a-zA-Z._]{1,255}$/],

                ['minLength', 1],

                ['maxLength', 255]
            ]
        },

        accountingEntry : {

            'type': 'textarea',

            'validators': [

                ['pattern', /^[a-zA-ZñÑÀÁÂÃÄÅàáâãäåÈÉÊËèéêëÌÍÎÏìíîïÒÓÔÕÖòóôõöÙÚÛÜùúû\s\d¡!¿?@*=,.:-_"']{1,255}$/],

                ['minLength', 1],

                ['maxLength', 255]
            ]
        },

        title :  {

            'type': 'text',

            'validators': [

                ['minLength', 5],

                ['maxLength', 25]
            ]
        },

        description : {

            'type': 'textarea',

            'validators': [

                ['minLength', 25],

                ['maxLength', 255]
            ]
        },

        currency : {

            'type': 'text',

            'currency': {prefix: '$ ', align: 'left'},

            'validators': []
        },

        currencyDivisible : {

            'type': 'text',

            'currency': {prefix: '$ ', align: 'left', thousands: ',', decimal: '.'},

            // 'divisible' : [2, 2],

            'validators': [

                ['min', 0.01]

            ]
        },

        generitPhone  : {

            'type': 'text',
            'validators': [
                ['pattern', /^(\+[0-9]{1,3})([0-9]{3})([0-9]{3})([0-9]{4})$/],
                ['maxLength', 13]
            ]

        },

        decimal : {

            'type': 'number',

            'validators': [

                ['min', 0],

                ['max', 2]
            ]
        },
        clientPhone : {

            'type': 'text',
            'validators': [
                ['pattern', /^([0-9]{3})([0-9]{3,4})([0-9]{4})$/],
                ['maxLength', 11]
            ]

        },

        identification : {

            'type': 'text',
            'validators': [
                ['pattern', /^([0-9]{6,11})$/],
                ['maxLength', 11]
            ]

        },

        usNumberPhone : {     // +1 (123) 333-3333

            'type': 'text',

            'mask': ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],

            'validators': [

                ['pattern', /^(\+1\s\([0-9]{3}\)\s[0-9]{3}\-[0-9]{4})$/],

                ['maxLength', 45]
            ]
        },

        coNumberPhone : {     // (123) 333-3333

            'type': 'text',

            'validators': [

                ['pattern', /^([0-9]{10})$/],

                ['maxLength', 10]
            ]
        },

        paNumberPhone : {     // (123) 3333-3333

            'type': 'text',

            'validators': [

                ['pattern', /^([0-9]{8})$/],

                ['maxLength', 8]
            ]
        },

        ecNumberPhone : {     // (123) 3333-3333

            'type': 'text',

            'validators': [

                ['pattern', /^([0-9]{9})$/],

                ['maxLength', 9]
            ]
        },

        usZipCode  : {

            'type': 'text',

            'validators': [

                ['pattern', /^([A-Z]{2})\s([0-9]{4,6})$/],
            ]
        },


        checkbox : {

            'type': 'checkbox',

            'validators': []
        },

        number : {

            'type': 'number',

            'validators': []
        },

        numberId : {

            'type': 'text',

            'validators': [

                ['pattern', /^([0-9]{7,10})$/],
            ]
        },

        personCount : {

            'type': 'number',

            'validators': [

                ['min', 1],

                ['max', 525]
            ]
        },

        childCount : {

            'type': 'number',

            'validators': [

                ['min', 0],

                ['max', 525]
            ]
        },

        pieceCount : {

            'type': 'text',

            'currency': {
                prefix: '', thousands: '', decimal: '',
                align: 'left', precision: 0, allowZero: true
            },

            'validators': []
        },


        usSocialID : {

            'type': 'text',

            'validators': [

                ['pattern', /^[0-9]+$/],

                ['maxLength', 25]
            ]
        },

        deliveryWeight : {

            'type': 'number',

            'validators': [['min', 0.5], ['max', 20]]
        },

        creditCardNumber : {

            'type': 'text',

            'mask': [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],

            'validators': [

                ['pattern', /^([0-9]{16})$/],

                ['maxLength', 16]
            ]
        },

        cardExpireDate : {

            'type': 'text',

            'mask': [/([01])/, /\d/, '\/', /\d/, /\d/],

            'validators': [

                ['pattern', /^([0-9]{2})\/([0-9]{2})$/],

            ]
        },

        cardExpireDatePayu : {

            'type': 'text',

            'mask': [/([2])/, /\d/, /\d/, /\d/, '\/', /\d/, /\d/],

            'validators': [

                ['pattern', /^([0-9]{4})\/([0-9]{2})$/],

            ]
        },

        cardExpireDatePaypal : {

            'type': 'text',

            'mask': [/([01])/, /\d/, '\/', /([2])/, /\d/, /\d/, /\d/],

            'validators': [

                ['pattern', /^([0-9]{2})\/([0-9]{4})$/],

            ]
        },

        cardCVV : {

            'type': 'text',

            'mask': [/\d/, /\d/, /\d/],

            'validators': [

                ['pattern', /^([0-9]{3,4})$/],

            ]
        },

        identityCard : {

            'type': 'text',

            'validators': [

             ['pattern', /^(([0-9]{1,2})|([Pp][Ee])|([Ee]-[0-9]{1,2})|([Nn])|([0-9]{1,2}[Aa][Vv])|([0-9]{1,2}[Pp][Ii]))-[0-9]{1,4}-[0-9]{1,5}$/],

            ]
        },

        domain : {

            'type': 'text',

            'validators': [

                ['pattern', /^(?!:\/\/)([a-zA-Z0-9-]+.)*[a-zA-Z0-9][a-zA-Z0-9-]+.[a-zA-Z]{2,11}?$/],

            ]
        },

        url : {

            'type': 'text',

            'validators': [

                ['pattern', /^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/],

            ]
        },

        percentages : {

            'type': 'number',

            'validators': [

                ['pattern', /^([0-9]{1,3})$/],

                ['min', 0],

            ]
        },
        number_passengers : {

            'type': 'number',

            'validators': [

                ['pattern', /^([0-9])$/],

                ['min', 1],

            ]
        },
        percent : {

            'type': 'number',

            'validators': [

                ['min', 0],

                ['max', 100]
            ]
        },

        time : {     // 14:12

            'type' : 'text',

            'mask' : [   /[0-2]/, /\d/, ':' , /[0-5]/, /\d/],

            'validators' : [
                ['pattern', /^(((?:^2[0-3])|(?:^[0-1]\d))?\:[0-5]{1}\d{1})$/],

                ['maxLength', 5 ]
            ]
        },

        min_currency : {

            'type': 'number',

            'validators': [

                ['min', 1]
            ]
        },

        max_currency : {

            'type': 'number',

            'validators': [

                ['min', 1]
            ]
        },
    };
}


