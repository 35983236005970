import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SearchService {

    // event search
    private searchSource = new Subject<string>();
    public searchEvent = this.searchSource.asObservable();

    // event reset search
    private searchReset = new Subject();
    public resetEvent = this.searchReset.asObservable();

    constructor() {}

    public doChange(value: string) {
        this.searchSource.next(value);
    }

    public doReset() {
        this.searchReset.next();
    }
}
