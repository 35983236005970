import { trigger, animate, transition, style} from '@angular/animations';

export const upToDown = trigger('upToDown', [
    transition(':enter', [
        style({
            'transform' : 'translate(0, -10px)',
            opacity : 0
        }),
        animate('0.2s ease-in-out', style({
            'transform' : 'translate(0, 0)',
            opacity: 1
        }))
    ]),
    transition(':leave', [
        style({
            'transform' : 'translate(0, 0)',
            opacity: 1
        }),
        animate('0.2s ease-in-out', style({
            'transform' : 'translate(0, 10px)',
            opacity : 0
        }))
    ])
]);

export const leftToRight = trigger('leftToRight', [
    transition(':enter', [
        style({
            'transform' : 'translate(-10px, 0)',
            opacity : 0
        }),
        animate('0.2s ease-in-out', style({
            'transform' : 'translate(0, 0)',
            opacity: 1
        }))
    ]),
    transition(':leave', [
        style({
            'transform' : 'translate(0, 0)',
            opacity: 1
        }),
        animate('0.2s ease-in-out', style({
            'transform' : 'translate(10px, 0)',
            opacity : 0
        }))
    ])
]);