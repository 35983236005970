import {ApisRoutesInterface} from './apis-routes.interface';


export class PublicsRoutes {

    // public
    public publics: ApisRoutesInterface = {
        url: {
            environments: '/publics/system/environments'
        }
    };
}
