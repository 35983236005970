import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ng-td',
    templateUrl: './ng-td.component.html',
    styleUrls: ['./ng-td.component.scss']
})
export class NgTdComponent implements OnInit {

    @Input() value: any;

    @Input() emptyCol = '-';

    constructor() { }

    ngOnInit() {

    }

    strValue(str): string {
        const subStr = (str + '').length > 50 ?
            (str + '').substr(0, (str + '').substr(0, 50).lastIndexOf(' ')) + '...' : str;
        return str ? subStr : this.emptyCol;
    }

    strCol(str): string {
        return str ? str : this.emptyCol;
    }

}
