<div class="mb-5">

    <ngx-spinner [fullScreen]="false" [name]="spinnerName"
                 bdColor="rgba(255,255,255,0.7)"
                 color="#343a40"
                 zIndex="1051"
                 size="medium">
    </ngx-spinner>

    <div *ngIf="entityId && filesList.length > 0">
        <file-preview-list [items]="filesList"
                           [viewItem]="viewFile"
                           [deleteItem]="deleteFile"
                           (update)="handleEditList($event)"
                           (delete)="handleCleanList($event)"
                           (takeView)="handleViewFile($event)"
                           (copy)="doCopy($event)"></file-preview-list>
    </div>

    <div class="_dropzone" ngx-dropzone [accept]="acceptFile" [hidden]="filesList.length === parameterLimit"
         (change)="onSelectInput($event)"
         [expandable]="true"
         [multiple]="multiple"
         [maxFileSize]="90000000"
         [disableClick]="files.length === parameterLimit" #dropzone>
        <ngx-dropzone-label>
            <div class="dz-default dz-message">
                <span class = "dz-default">{{lang.new}}</span>
            </div>
        </ngx-dropzone-label>
        <file-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let item of files"
                      [file]="item.file"
                      [item]="item"
                      [removable]="true"
                      (delete)="handleClean($event)"
                      (update)="handleEdit($event)">
        </file-preview>
    </div>

    <ngb-progressbar *ngIf="doLoading" class="mt-2" showValue="true" type="primary" [value]="uploadPercent"></ngb-progressbar>
</div>
