import {Directive, ElementRef, Injectable, Input} from '@angular/core';
import {CredentialService} from '../_services/credential.service';

@Directive({
    selector: '[credential]'
})
export class CredentialDirective {

    constructor(public cl: CredentialService, public el: ElementRef) { }

    @Input() set credential(credentials: Array<string> | Array<Array<string>>) {
        if (credentials) {
            if (!this.cl.checkCredential(credentials)) {
                this.el.nativeElement.remove();
            }
        }
    }

}
