import {ApisRoutesAuthInterface} from './apis-routes.interface';

export class AuthRoutes {

    // auth
    public auth: ApisRoutesAuthInterface = {
        login: '/auth/login',
        logout: '/auth/logout',
        forgot: '/auth/forgot',
        reset: '/auth/reset',
        verification: {
            resend: '/auth/verification/resend',
            verify: '/auth/verification/verify'
        },
        two_factor: {
            login: '/auth/two-factor/login',
            enable: '/auth/two-factor/enable',
            store: '/auth/two-factor/store',
            disable: '/auth/two-factor/destroy'
        },
        password: {
            confirm: '/auth/password/confirm'
        }
    };
}
